import styled from '@emotion/styled';
import { LIVE_MAP_BILLING_CYCLE } from '@racemap/utilities/consts/billing';
import humanizeDuration from 'humanize-duration';

export const EventCycleHint = () => {
  const billingCycleDuration = humanizeDuration(LIVE_MAP_BILLING_CYCLE, {
    units: ['d'],
    largest: 1,
  });

  return (
    <HintContainer>
      <section>
        Event cycles last for {billingCycleDuration} each, and charges for Map and used Add-Ons
        apply at the start of each cycle. Here's how event cycles work:
      </section>
      <ol>
        <li>
          <strong>Upcoming event:</strong> For events that have not yet started, you can adjust the
          start and end times as needed. The first cycle is initiated when the event becomes live at
          the specified start time, incurring charges for Map and used Add-Ons. If the event extends
          beyond the initial {billingCycleDuration}-day period, as indicated by the end time, a new
          cycle starts, incurring additional charges. Importantly, changing the start and end times
          backward does not reduce previously incurred charges.
        </li>

        <li>
          <strong>Ongoing usage:</strong> After your event has ended, you can pause usage for an
          extended period and then resume by adjusting the event times. Once the end time is
          reached, no further cycles are determined. During these inactive periods, there are no
          charges for Maps and Add-Ons.{' '}
        </li>

        <li>
          <strong>Past event:</strong> In rare cases, you may specify a start time in the past. In
          this scenario, the event has already started, and one or more cycles are initiated upon
          event activation, resulting in charges for the corresponding number of Maps and Add-Ons.
        </li>
      </ol>
    </HintContainer>
  );
};

const HintContainer = styled.div`
    padding:5px;
    section{
      margin-bottom: 1rem;
    }
  
    ol{
      padding-left: 1rem;
      li{
        margin-bottom: 0.5rem;
      }
  
      li::marker
      {
        font-weight: bold;
      }
    }
  `;
