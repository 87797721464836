import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import React, { FC } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { Checkbox, CheckboxValues } from '../Checkbox';
import { HStack } from '../MetaComponent';
import { CollapseButton, CollapseState } from './CollapseButton';
import { TreeItemInfo } from './Node';

interface Props {
  level: number;
  withLines: boolean;
  branchKey: string;
  label: React.ReactNode;
  lastItemOfBranch: boolean;
  parentNodeInfos: Array<TreeItemInfo>;
  selectable: boolean;
  isParent: boolean;
  selectionValue?: CheckboxValues;
  onSelectionChange?: (newSelectionValue: boolean) => void;
  collapsed?: CollapseState;
  onCollapseChange?: (newCollapseState: CollapseState) => void;
  dragRef?: ConnectDragSource;
  canDrag?: boolean;
  isDragging?: boolean;
  count?: number;
}

// eslint-disable-next-line new-cap
const numberFormatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 });

export const Leaf: FC<Props> = ({
  level,
  withLines,
  branchKey,
  label,
  lastItemOfBranch,
  parentNodeInfos,
  selectable,
  isParent,
  selectionValue,
  onSelectionChange,
  collapsed,
  onCollapseChange,
  dragRef,
  canDrag,
  isDragging,
  count,
}) => {
  const isSelectable = selectable && selectionValue != null;
  const isVisible = !parentNodeInfos
    .slice(level)
    .some((pNI) => pNI.collapseState !== CollapseState.Open);
  const isSearchLeaf = branchKey === 'search';
  const isInteractable = !isSearchLeaf && (isSelectable || canDrag || !lastItemOfBranch);

  if (!isVisible) return <></>;

  const handleLabelClick = () => {
    if (isSelectable && onSelectionChange) {
      onSelectionChange(!selectionValue);
      return;
    }

    if (!lastItemOfBranch && onCollapseChange)
      onCollapseChange(
        collapsed === CollapseState.Open ? CollapseState.Closed : CollapseState.Open,
      );
  };

  return (
    <Container
      level={level + (isSelectable ? 1 : 0)}
      canDrag={canDrag}
      isDragging={isDragging}
      isInteractable={isInteractable}
    >
      {Array(level)
        .fill(1)
        .map((_, i) => {
          if (
            !withLines ||
            (parentNodeInfos[i + 1] != null && parentNodeInfos[i + 1].lastItemOfBranch)
          )
            return <div key={`${i}_empty`} />;
          if (i === level - 1) {
            if (collapsed != null && !isParent)
              return (
                <CollapseButton
                  key={`${i}_collapse_button`}
                  value={collapsed}
                  onChange={(newState) => onCollapseChange?.(newState)}
                />
              );
            if (lastItemOfBranch) return <EndCorner key={`${i}_end_corner`} />;
            return <Corner key={`${i}_corner`} />;
          }
          return <VerticalLine key={`${i}_vertical_line`} />;
        })}
      {isSelectable && (
        <Checkbox
          size={14}
          value={selectionValue}
          onChange={(newValue) => onSelectionChange?.(newValue)}
        />
      )}
      <HStack className="label-and-count" onClick={handleLabelClick}>
        <LabelField ref={dragRef}>{label}</LabelField>
        {count != null && <CountField>{numberFormatter.format(count)}</CountField>}
      </HStack>
    </Container>
  );
};

const Container = styled.div<{
  level: number;
  canDrag?: boolean;
  isDragging?: boolean;
  isInteractable: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${({ level }) => Array(level).fill('20px').join(' ')} 1fr;
  grid-column-end: -1;
  align-items: center;
  position: relative;

  ${({ canDrag, isInteractable }) =>
    canDrag ? 'cursor: grab;' : isInteractable ? 'cursor: pointer;' : ''}
  ${({ isDragging }) => (isDragging ? `color: ${RacemapColors.PaleBlue};` : '')}

  transition: color 300ms ease;

  :hover > .rm-checkbox,  
  :hover > .label-and-count {
    ${({ isInteractable }) =>
      isInteractable ? `background-color: ${RacemapColors.CloudBlue};` : ''}
  }
`;

const LabelField = styled.div`
  font-weight: 400;
  max-width: calc(100% - 20px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CountField = styled.div`
  color: ${RacemapColors.DarkGray};
  margin-left: 4px;
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${RacemapColors.Gray};
  width: 100%;
  height: 100%;
  transform: translateX(50%);
  z-index: 10;
`;

const Corner: FC = () => {
  return (
    <VerticalLine>
      <div
        style={{ borderBottom: `1px solid ${RacemapColors.Gray}`, width: '40%', height: '50%' }}
      />
      <div />
    </VerticalLine>
  );
};

const EndCorner: FC = () => {
  return (
    <div style={{ width: '100%', height: '100%', transform: 'translate(50%)', zIndex: 10 }}>
      <div
        style={{
          borderBottom: `1px solid ${RacemapColors.Gray}`,
          width: '40%',
          height: '50%',
          borderLeft: `1px solid ${RacemapColors.Gray}`,
        }}
      />
      <div />
    </div>
  );
};
