import { isNull } from 'lodash';
import { DateTime, StringUnitLength } from 'luxon';
import { formatShortRelativeTime } from './formatShortRelativeTime';

export type TimeVariantInput = string | number | Date | DateTime;

export function castDateTime(input: TimeVariantInput): DateTime {
  if (input instanceof DateTime) {
    return input;
  }
  if (typeof input === 'string') {
    return DateTime.fromISO(input);
  }
  if (typeof input === 'number') {
    return DateTime.fromMillis(input);
  }
  if (input instanceof Date) {
    return DateTime.fromJSDate(input);
  }
  throw new Error('Invalid date');
}

export const getRelativeTime = (
  time: TimeVariantInput,
  baseTime?: TimeVariantInput,
  size: StringUnitLength = 'long',
) => {
  const targetDateTime = castDateTime(time);
  const baseDateTime = baseTime ? castDateTime(baseTime) : DateTime.now();
  const diffSec = targetDateTime.diff(baseDateTime, 'seconds').seconds;

  if (Math.abs(diffSec) < 60) {
    return formatShortRelativeTime(diffSec, size);
  }
  return (
    targetDateTime.toRelative({
      base: baseDateTime,
      style: size,
    }) || ''
  );
};
