export const advancedJSONStringify = (obj: any): string => {
  return JSON.stringify(obj, (key, value) => {
    if (value instanceof Set) {
      return {
        dataType: 'Set',
        value: Array.from(value),
      };
    }
    return value;
  });
};

export const advancedJSONParse = (json: string) => {
  return JSON.parse(json, (key, value) => {
    if (value && value.dataType === 'Set') {
      return new Set(value.value);
    }
    return value;
  });
};
