import { ObjectId } from '@racemap/sdk/schema/base';
import { lineString } from '@turf/helpers';
import length from '@turf/length';
import { Feature, Geometry, LineString } from 'geojson';
import { Draft, Immutable, produce } from 'immer';
import { RacemapColors } from '../consts/common';
import { SportTypes } from '../consts/eventTypes';
import { eventTypesByType } from '../consts/eventTypes';
import { MapType } from '../consts/events';
import { mapStyles, mapboxBaseURL } from '../consts/map';
import { calculateDistanceInMeter } from '../point_utils';
import { RacemapEvent } from '../types/events';
import {
  EditMode,
  EventTrackObject,
  EventTrackProperties,
  EventTrackPrototypeObject,
  GeoObjectProperties,
  LayerType,
  LineStringObject,
  POIProperties,
  POIPrototypeObject,
  PointOnLineStringObjectProperties,
  RacemapFeatureCollection,
  RacemapGeoTypes,
  SplitObject,
  SplitObjectProperties,
  SplitPrototypeObject,
  WMSLayerObject,
} from '../types/geos';
import { getSplitsOfTrack } from './event';
import { isNotEmptyString } from './utils';
import { isPoint, isPointOfInterest, isRacemapTrack, isSplitPoint } from './validation';

export function decorateFeature<T extends Feature<Geometry | null, any> & { id: string }>(
  feature: T,
  eventId: string,
  eventSport: SportTypes | null,
  editMode?: EditMode,
): Feature<T['geometry'], T['properties'] & GeoObjectProperties> & { id: string } {
  return produce(feature, (f: Draft<T>) => {
    // add new id to element if element has no id or the id is not from the current event
    if (typeof f.id !== 'string' || f.properties.eventId !== eventId)
      f.id = new ObjectId().toHexString();

    if (
      (f.geometry == null && f.properties.racemapType !== RacemapGeoTypes.EXTERNAL_LAYER) ||
      (f.geometry != null && f.geometry.type !== 'LineString' && f.geometry.type !== 'Point')
    ) {
      return;
    }
    const nameSuffix = f.id.substring(f.id.length - 5);

    if (f.geometry?.type === 'LineString') {
      f.properties = {
        ...getNewTrackProperties(f.geometry, eventId),
        ...f.properties,
        eventId,
        name: isNotEmptyString(f.properties.name) ? f.properties.name : `Track ${nameSuffix}`,
      };
    }

    if (f.geometry?.type === 'Point') {
      if (editMode?.targetElementType === RacemapGeoTypes.SPLIT || isSplitPoint(f)) {
        f.properties = {
          // track id, coordiate index and linestring id by the created point
          ...getNewSplitProperties(
            eventId,
            f.properties.lineStringId,
            f.properties.coordinateIndex,
            -1,
            eventSport,
          ),
          ...f.properties,
          eventId,
          name: isNotEmptyString(f.properties.name) ? f.properties.name : `Split ${nameSuffix}`,
        };
      } else if (editMode == null || editMode.targetElementType === RacemapGeoTypes.POI) {
        f.properties = {
          ...getNewPOIProperties(eventId),
          ...f.properties,
          eventId,
          name: isNotEmptyString(f.properties.name) ? f.properties.name : `POI ${nameSuffix}`,
        };
      }
    }

    if (f.properties.racemapType === RacemapGeoTypes.EXTERNAL_LAYER) {
      f.properties = {
        ...getNewExternalLayerProperties(eventId, f.properties.name),
        ...f.properties,
        eventId,
      };
    }
  });
}

export const hasShadowtrack = (event: Immutable<RacemapEvent>) => {
  return event.geo?.shadowtrackId != null;
};

export function getShadowtrack(
  event?: Immutable<{ geo: RacemapEvent['geo'] }> | null,
): EventTrackObject | null {
  if (event?.geo?.features == null || event?.geo?.shadowtrackId == null) return null;

  const shadowtrack = event.geo.features.find(
    (feature) => event?.geo?.shadowtrackId === feature.id,
  );
  if (!isRacemapTrack(shadowtrack)) return null;
  return shadowtrack;
}
export const hasSplits = (event: RacemapEvent | Immutable<RacemapEvent> | null): boolean => {
  if (event?.geo?.features == null) return false;

  const shadowtrack = getShadowtrack(event);
  if (shadowtrack == null) return false;
  const splits = getSplitsOfTrack(event);

  return splits.length > 2;
};

export const hasPois = (event: RacemapEvent | Immutable<RacemapEvent> | null): boolean => {
  if (event?.geo?.features == null) return false;

  return event.geo.features.some(
    (feature) => feature.properties.racemapType === RacemapGeoTypes.POI,
  );
};

export function getStyleURL(key: MapType | null): string {
  const defaultUrl = mapboxBaseURL + mapStyles.TERRAIN.key;
  if (key == null) return defaultUrl;
  const styleObj = mapStyles[key];

  if (styleObj == null) return defaultUrl;

  return mapboxBaseURL + styleObj.key;
}

type Style = {
  label: string;
  key: string;
};

export function getStyle(key: MapType): Style {
  return mapStyles[key];
}

export function getStyleByURL(url: string): Style {
  const styleId = getStyleId(url);
  return getStyle(styleId);
}

export function getStyleId(url: string | null): MapType {
  for (const [id, value] of Object.entries(mapStyles)) {
    if (mapboxBaseURL + value.key === url) return id as MapType;
  }
  return MapType.TERRAIN;
}

export function getLineStringLength(geometry: LineStringObject['geometry']): number {
  const lastPosition = geometry.coordinates[0];
  return geometry.coordinates.reduce((length, currentPosition) => {
    return (
      length +
      calculateDistanceInMeter(
        currentPosition[0],
        currentPosition[1],
        lastPosition[0],
        lastPosition[1],
      )
    );
  }, 0);
}

export function getNewTrack(
  eventId: string,
  geometry?: EventTrackPrototypeObject['geometry'],
): Omit<EventTrackPrototypeObject, 'properties'> & {
  properties: EventTrackProperties<GeoObjectProperties>;
} {
  const finGeometry = geometry || {
    type: 'LineString',
    coordinates: [],
  };

  return {
    type: 'Feature',
    geometry: finGeometry,
    properties: getNewTrackProperties(finGeometry, eventId),
  };
}

export function getNewTrackProperties(
  geometry: LineString,
  eventId: string,
  name = '',
): EventTrackProperties<GeoObjectProperties> {
  return {
    racemapType: RacemapGeoTypes.TRACK,
    name,
    eventId,
    color: RacemapColors.PaleBlue,
    hidden: false,
    pointCount: geometry.coordinates.length - 1,
    length: getLineStringLength(geometry),
  };
}

export function getNewPOI(
  eventId: string,
  geometry?: POIPrototypeObject['geometry'],
): POIPrototypeObject {
  const finGeometry = geometry || {
    type: 'Point',
    coordinates: [],
  };

  return {
    type: 'Feature',
    geometry: finGeometry,
    properties: getNewPOIProperties(eventId),
  };
}

export function getNewPOIProperties(
  eventId: string,
  name = '',
): POIProperties<GeoObjectProperties> & { lineStringId: undefined } {
  return {
    racemapType: RacemapGeoTypes.POI,
    name,
    shortName: '',
    color: RacemapColors.PaleBlue,
    description: '',
    icon: null,
    hidden: false,
    eventId,
    lineStringId: undefined,
  };
}

export function getNewPointOnLineStringProperties(
  eventId: string,
  type: RacemapGeoTypes.POI | RacemapGeoTypes.SPLIT | null,
  lineStringId: string,
  coordinateIndex: number,
  name = '',
): PointOnLineStringObjectProperties {
  return {
    racemapType: type || RacemapGeoTypes.SPLIT,
    name,
    shortName: '',
    color: RacemapColors.PaleBlue,
    eventId,
    hidden: false,
    lineStringId: lineStringId,
    coordinateIndex: coordinateIndex,
  };
}

export function getNewExternalLayerProperties(
  eventId: string,
  name = '',
): WMSLayerObject['properties'] {
  return {
    racemapType: RacemapGeoTypes.EXTERNAL_LAYER,
    name,
    layerType: LayerType.WMS,
    tiles: [],
    tileSize: 256,
    eventId,
  };
}

export function getNewSplitProperties<ID extends string | ObjectId = string>(
  eventId: ID,
  trackId: string,
  coordinateIndex: number,
  offset: number,
  eventSport: SportTypes | null,
  name = '',
): SplitObjectProperties {
  return {
    ...getNewPointOnLineStringProperties(
      eventId.toString(),
      RacemapGeoTypes.SPLIT,
      trackId,
      coordinateIndex,
    ),
    racemapType: RacemapGeoTypes.SPLIT,
    newSport: eventSport || '',
    timekeeping: false,
    readerIds: [],
    offset,
    icon: null,
    name,
    resetSpeed: null,
    minSpeed: getRecommendSplitMinSpeed(eventSport),
    maxSpeed: getRecommendSplitMaxSpeed(eventSport),
  };
}

export function getNewSplit<ID extends string | ObjectId = string>(
  eventId: ID,
  coordinateIndex: number,
  referenceTrack: LineStringObject & { id: ID },
  eventSport: SportTypes | null,
  name = '',
  properties: Partial<SplitObjectProperties> = {},
): SplitPrototypeObject<ID> {
  const trackGeometry = referenceTrack.geometry;
  const coordinates = trackGeometry.coordinates[coordinateIndex];
  const offset = calculateOffsetOnTrackInMeters(trackGeometry, coordinateIndex);

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates,
    },
    properties: {
      ...getNewSplitProperties<ID>(
        eventId,
        referenceTrack.id,
        coordinateIndex,
        offset,
        eventSport,
        name,
      ),
      ...properties,
    },
  };
}

export function getRecommendSplitMinSpeed(sport: SportTypes | null): number {
  if (sport == null) return 0.1;
  const sportObject = eventTypesByType[sport];

  return sportObject.minSpeed || 0.1;
}

export function getRecommendSplitMaxSpeed(sport: SportTypes | null): number {
  if (sport == null) return 360;
  const sportObject = eventTypesByType[sport];

  return sportObject.maxSpeed || 360;
}

export function calculateOffsetOnTrackInMeters(
  geometry: Immutable<LineString>,
  pointIndex: number,
): number {
  if (pointIndex === 0) return 0;

  return (
    length(lineString(geometry.coordinates.slice(0, pointIndex + 1).map((v) => v.concat())), {
      units: 'kilometers',
    }) * 1000
  );
}

export function toGeoJSONString(geo: Immutable<RacemapFeatureCollection>): string {
  return JSON.stringify(geo);
}

export function toGPXString(geo: Immutable<RacemapFeatureCollection>): string {
  const tracks = geo.features.filter(isRacemapTrack);
  const points = geo.features.filter(isPoint);

  const gpxTracks = tracks.map(
    (track) => `
  <trk>
    <name>${track.properties.name}</name>
    <rmx:id>${track.id}</rmx:id>
    ${propertiesToXML(track.properties)}
    <trkseg>${track.geometry.coordinates
      .map(
        (c) => `
      <trkpt  lon="${c[0]}" lat="${c[1]}">
        ${c.length === 3 ? `<ele>${c[2]}</ele>` : ''}
      </trkpt>`,
      )
      .join('')}
    </trkseg>
  </trk>`,
  );

  const gpxPOIs = points.map(
    (point) => `
  <wpt lon="${point.geometry.coordinates[0]}" lat="${point.geometry.coordinates[1]}">
    <name>${point.properties.name}</name>
    <rmx:id>${point.id}</rmx:id>
    ${propertiesToXML(point.properties)}
    ${isSplitPoint(point) || isPointOfInterest(point) ? `<sym>${point.properties.icon}</sym>` : ''}
    ${point.geometry.coordinates.length === 3 ? `<ele>${point.geometry.coordinates[2]}</ele>` : ''}
  </wpt>`,
  );

  return `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<gpx version="1.1" creator="Racemap">
  <metadata><time>${new Date().toISOString()}</time></metadata>${
    gpxTracks.length > 0 ? gpxTracks.join('') : ''
  }${gpxPOIs.length > 0 ? gpxPOIs.join('') : ''}
</gpx>
  `;
}

export function toKMLString(geo: Immutable<RacemapFeatureCollection>): string {
  const tracks = geo.features.filter(isRacemapTrack);
  const points = geo.features.filter(isPoint);

  const kmlTracks = tracks.map(
    (track) => `
    <Placemark id="${track.id}">
      <name>${track.properties.name}</name>
      ${propertiesToXML(track.properties)}
      <LineString>
        <coordinates>${track.geometry.coordinates
          .map((c) => `${c[0]},${c[1]}${c.length === 3 ? `,${c[2]}` : ''}`)
          .join(' ')}
        </coordinates>
      </LineString>
    </Placemark>`,
  );

  const kmlPoints = points.map(
    (point) => `
    <Placemark id="${point.id}">
      <name>${point.properties.name}</name>
      ${propertiesToXML(point.properties)}
      <Point>
        <coordinates>${point.geometry.coordinates[0]},${point.geometry.coordinates[1]}${
      point.geometry.coordinates.length === 3 ? `,${point.geometry.coordinates[2]}` : ''
    }</coordinates>
      </Point>
    </Placemark>`,
  );

  return `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<kml xmlns="http://www.opengis.net/kml/2.2" creator="Racemap">
  <Document>${kmlTracks.length > 0 ? kmlTracks.join('') : ''}
  ${kmlPoints.length > 0 ? kmlPoints.join('') : ''}
  </Document>
</kml>
  `;
}

const propertiesToXML = <T extends Partial<Record<keyof T, T[keyof T]>>>(properties: T): string => {
  let output = '';

  for (const key in properties) {
    if (!Object.hasOwn(properties, key)) continue;

    const value = properties[key];

    if (value !== null) {
      output += `    <rmx:${key.toLowerCase()}>${value}</rmx:${key.toLowerCase()}>\n`;
    }
  }

  return output;
};

export function buildGeoJsonBlob(geo: Immutable<RacemapFeatureCollection>): Blob {
  return new Blob([toGeoJSONString(geo)], {
    type: 'application/vnd.geo+json',
  });
}

export function buildGPXBlob(geo: Immutable<RacemapFeatureCollection>): Blob {
  return new Blob([toGPXString(geo)], {
    type: 'application/gpx+xml',
  });
}

export function buildKMLBlob(geo: Immutable<RacemapFeatureCollection>): Blob {
  return new Blob([toKMLString(geo)], {
    type: 'application/kml+xml',
  });
}

/**
 * Removes the decimal part of a number without rounding up.
 * @param {number} n
 * @returns {number}
 */
function truncate(n: number) {
  return n > 0 ? Math.floor(n) : Math.ceil(n);
}

export function convertDecimalToDMS(
  decimal: number,
  lngOrLat: 'lng' | 'lat',
): [number, number, number, string] {
  if (isNaN(decimal)) {
    throw new TypeError('coordinates must to be a number');
  } else if (lngOrLat === 'lat' && (decimal < -90 || decimal > 90)) {
    throw new RangeError('latitude must be between -90 and 90');
  } else if (decimal < -180 || decimal > 180) {
    throw new RangeError('longitude must be between -180 and 180');
  }

  const hemisphere = lngOrLat === 'lng' ? (decimal < 0 ? 'W' : 'E') : decimal < 0 ? 'S' : 'N';

  const decimalAbs = Math.abs(decimal);
  const degrees = truncate(decimalAbs);
  const minutes = truncate((decimalAbs - degrees) * 60);
  const seconds = (decimalAbs - degrees - minutes / 60) * 60 ** 2;

  return [degrees, minutes, seconds, hemisphere];
}

// convert position with decimal degrees to dms(degrees minutes seconds)
export function convertDecimalCoordinateToDMS(
  location: { lat?: number; lng?: number } | undefined,
): string {
  if (location == null || location.lat == null || location.lng == null) return '--';
  const { lat, lng } = location;
  const latDMS = convertDecimalToDMS(lat, 'lat');
  const lngDMS = convertDecimalToDMS(lng, 'lng');

  return `${latDMS[0]}° ${latDMS[1]}' ${latDMS[2].toFixed(4)}" ${latDMS[3]}, ${lngDMS[0]}° ${
    lngDMS[1]
  }' ${lngDMS[2].toFixed(4)}" ${lngDMS[3]}`;
}

export type SplitWithOffset = Immutable<
  Feature<SplitObject['geometry'], SplitObject['properties'] & { offset: number }> & { id: string }
>;

export const hasSplitOffset = (split: Immutable<SplitObject>): split is SplitWithOffset => {
  return isSplitPoint(split) && split.properties.offset != null;
};
