import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import React, { FC } from 'react';
import { IconMinus, IconPlus } from '../../Icon';
import { Center } from '../MetaComponent';

export enum CollapseState {
  Open = 0,
  Closed = 1,
}

interface Props {
  value: CollapseState;
  onChange: (newValue: CollapseState) => void;
}

export const CollapseButton: FC<Props> = ({ value, onChange }) => {
  const handleClick = () => {
    if (value === CollapseState.Open) {
      onChange(CollapseState.Closed);
    } else {
      onChange(CollapseState.Open);
    }
  };

  return (
    <Container>
      <Button onClick={handleClick}>
        {value === CollapseState.Open ? (
          <IconMinus fixedWidth fontSize={8} />
        ) : (
          <IconPlus fixedWidth fontSize={8} />
        )}
      </Button>
    </Container>
  );
};

const Container = styled(Center)``;

const Button = styled(Center)`
  margin: 3px;
  border: solid 1px ${RacemapColors.DarkGray};
  width: 12px;
  height: 12px;
  border-radius: 1px;
  box-shadow: 1px 1px ${RacemapColors.LightGray};
  background-color: white;
  cursor: pointer;

  :hover {
    background-color: ${RacemapColors.LightGray};
  }
`;
