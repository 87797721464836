import { BillableItemTypes } from '@racemap/sdk/schema/user';
import { DateTime } from 'luxon';
import { OneDayInMillis } from './time';

export function isTest(apiKey: string) {
  return apiKey.includes('_test_');
}

export type BillableItemTypesWithPrice = Exclude<
  BillableItemTypes,
  | BillableItemTypes.ACTIVATE_EVENT
  | BillableItemTypes.EVENT_DAY
  | BillableItemTypes.FREE_DATA_FEED
  | BillableItemTypes.FREE_EVENT_CYCLE
  | BillableItemTypes.FREE_MONITOR
  | BillableItemTypes.FREE_SPONSOR
  | BillableItemTypes.FREE_TIMING
  | BillableItemTypes.EXTEND_EVENT
  | BillableItemTypes.TIMING
  | BillableItemTypes.MONITOR
  | BillableItemTypes.DATA_FEED
>;

export type TaxRates = Array<string>;

export const getTaxRatesByMode = (env: 'test' | 'live'): TaxRates => {
  return env === 'test' ? taxRatesTestMode : taxRatesLiveMode;
};

const taxRatesTestMode = ['txr_1EwEMPKsOC83njP61ghCePbJ'];
const taxRatesLiveMode = ['txr_1FJ03BKsOC83njP6m1xjnDXY'];

// TODO: That is only a workaround. Later we should store the sim cards and
// connect that with the user.
export const SIM_GROUP_MAPPING_LIVE: Record<string, string> = {
  'Aaron Bass (Arté': 'cus_OfmE0SuUdoaivO',
  Abavent: 'cus_FdH2cWOoVJotFo',
  Ario: 'cus_KLShvI3OqR3wLr',
  artefacturbain: 'cus_OfmE0SuUdoaivO',
  Bentang: 'cus_NQHNnqHAoHlDUF',
  'Canada Jean Fort': 'cus_QCD6MoIEMifHx6',
  dolomitiming: 'cus_JUnpfLLT8P6aBz',
  EIEV: 'cus_NT1r51mfVW7u5e',
  'Global-Sportserv': 'cus_FdHQTd7NHP2j0K',
  'Green Cycling': 'cus_MAEihJgALFnDRR',
  'go-timing': 'cus_FdGqepllkLUF3L',
  'Haydn Hertz': 'cus_Kbfx1N2qIWdkbL',
  'Imagine Studio': 'cus_FdHOwd6UbsRSiW',
  IntrepidSpirit: 'cus_FdHFWXTsHHLrwP',
  'Julin Oapirat P': 'cus_FdHBuLjj3IVvZ2',
  'Julin Oapirat (P': 'cus_FdHBuLjj3IVvZ2',
  'Laufservice-Jena': 'cus_FdGpDVWRfxCiRU',
  'MOOV Limited': 'cus_JKYMWJr7qZs9nL',
  'Neumann-OL': 'cus_MlkwW8GUikDNXG',
  'Primeworks Solut': 'cus_FdHBuLjj3IVvZ2',
  racearabia: 'cus_P9fX5zORqLFrCi',
  'Racemap Pro': 'cus_P8wfVOaTJDkiZk',
  Racemap: 'cus_P8wfVOaTJDkiZk',
  Radioproposta: 'cus_LgP109xtGZifTo',
  'S-Time': 'cus_GhKPN3LlmoKZC3',
  sensry: 'cus_KU9ocYWWnr8uR9',
  'Sog Events': 'cus_FdHB9OCHVybcqB',
  'Speed-timing': 'cus_LKhEDtZAPuzF2v',
  Sporkrono: 'cus_FdHAopa9wzxeJ3',
  stefanwagner: 'cus_NXFUbZvGVyZVO3',
  Time2Time: 'cus_HwXogkZTVBOLH2',
  Time2Win: 'cus_GCwzAiu5PAdjKK',
  TimeNowSports: 'cus_FlCoOfv0dIH4zx',
  'Tomato Timing': 'cus_GaHGlxjZhq3xou',
  Tracker4Trails: 'cus_FdHPsA2CElR9o4',
  'Triathlon Spreew': 'cus_FdHEsBa1WXzFXy',
  'UNTAMED Sport Cl': 'cus_PSqnuZOYyUJBdW',
  Vasileos: 'cus_FdH2XmaSkH2kXz',
  videocom: 'cus_FdHNlSEhiSzL97',
  'Wendeline Lauxen': 'cus_KHNJvPypmygqWp',
  'WR Events Limite': 'cus_FdGqUHgsjheGvT',
  'Juha Seppälä': 'cus_OqhVYhIZC8Hhq8',
  'Alexander Galimb': 'cus_N16VfMpDPjZo26',
  'Bentang Jelajah Nusantara': 'cus_NQHNnqHAoHlDUF',
  'Corfu Mountain T': 'cus_FdH2XmaSkH2kXz',
  'Daniele Frimaire (Radio Proposta)': 'cus_LgP109xtGZifTo',
  'Datasport German': 'cus_FdH2cWOoVJotFo',
  DimensionData: 'cus_NtofTmTYNkSdiZ',
  'GSI-SPORTS-TIMIN': 'cus_Pgi3GjKPSSJjl1',
  'Jabor Shabeeb': 'cus_OXebJpVcPYiw4I',
  'Martin Eisenknap': 'cus_GCwzAiu5PAdjKK',
  Pipervision: 'cus_LrwjeELgcRcTaP',
  Ringvaart: 'cus_NJU5PlZCI7c9Dp',
  'Robert Watson (Intrepid Spirit)': 'cus_FdHFWXTsHHLrwP',
  'Roland Betzmann': 'cus_FdHB9OCHVybcqB',
  'Roland Betzmann ': 'cus_FdHB9OCHVybcqB',
  'Roveda Massimiliano (Keepsporting)': 'cus_JUnpfLLT8P6aBz',
  'Stephane Leclercqz (SPORKRONO)': 'cus_FdHAopa9wzxeJ3',
  'Timo Jahn (Laufs': 'cus_FdGpDVWRfxCiRU',
  'Tobias Jomrich': 'cus_FdHO0yZIKsdTrz',
  'Tobias Jomrich ': 'cus_FdHO0yZIKsdTrz',
  'Tomislav Loncar (Imagine Studio)': 'cus_FdHOwd6UbsRSiW',
  Trash: 'cus_P8wfVOaTJDkiZk',
  'Udo Küster | MTV': 'cus_OS9jZQ3UOWM8qa',
  'USP-Sport': 'cus_FdHOcTvPuRQzUL',
  'Uwe Renz (go-tim': 'cus_FdGqepllkLUF3L',
};

export const SIM_GROUP_MAPPING_TEST: Record<string, string> = {
  'TEST Group': 'cus_FdKxToOEhNzirJ', // Local Dev Test User
  'TEST Group Reseller': 'cus_P7miLx41CUtI1M', // Local Dev Test User
  EIEV: 'cus_FdL1V6a6m7pRhW',
  'Julin Oapirat (P': 'cus_G7e469qmQ77zCX',
  'Racemap Pro': 'cus_P7miLx41CUtI1M',
  'Primeworks Solut': 'cus_FdKxIpdEl6Yl5E',
  Abavent: 'cus_FdL0RaWuHUCTjW',
  Privatkunde: 'cus_LqTI1sOGYB8JyI',
};

export const LIVE_MAP_BILLING_CYCLE = 90 * OneDayInMillis;
export const DEVICE_TIMEOUT = 6 * OneDayInMillis;
export const TRACKER_MANAGEMENT_BILLING_CYCLE = 30 * OneDayInMillis;
export const CURRENT_SUBSCRIPTION_VERSION = '2020-09' as const;

export enum PRICING_VERSIONS {
  V2016_01 = '2016-01',
  V2019_08 = '2019-08',
  V2020_09 = '2020-09',
  V2024_08 = '2024-08',
}

// TODO: use correct start and endate
export const PRICING_VERSION_TABLE = {
  [PRICING_VERSIONS.V2016_01]: {
    from: DateTime.fromISO('2016-01-01T00:00:00Z'),
    to: DateTime.fromISO('2019-08-01T00:00:00Z'),
    numDaysEventCycle: 90,
  },
  [PRICING_VERSIONS.V2019_08]: {
    from: DateTime.fromISO('2019-08-01T00:00:00Z'),
    to: DateTime.fromISO('2020-09-01T00:00:00Z'),
    numDaysEventCycle: 90,
  },
  [PRICING_VERSIONS.V2020_09]: {
    from: DateTime.fromISO('2020-09-01T00:00:00Z'),
    to: DateTime.fromISO('2024-08-01T00:00:00Z'),
    numDaysEventCycle: 90,
  },
  [PRICING_VERSIONS.V2024_08]: {
    from: DateTime.fromISO('2024-08-01T00:00:00Z'),
    to: null,
    numDaysEventCycle: 7,
  },
};
