import styled from '@emotion/styled';
import { MaxMobileWindowWidth, RacemapColors } from '@racemap/utilities/consts/common';
import { Button, Flex, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { AddTrackingMapButton } from './AddTrackingMapButton';
import { AdminHeaderLinks } from './AdminHeaderLinks';
import { UserLinks } from './UserLinks';

const CollapseBreakpoint = MaxMobileWindowWidth - 1;

export function AdminHeader() {
  const headerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const isCollapsed = windowWidth <= CollapseBreakpoint && windowWidth > 0;

  // Close the navbar when clicking outside
  useOnClickOutside(headerRef, () => setMenuExpanded(false));

  // Close the navbar when the pathname changes
  useEffect(() => {
    setMenuExpanded(false);
  }, [location.pathname]);

  return (
    <StyledNavbar
      collapseOnSelect
      ref={headerRef}
      fixed="top"
      role="navigation"
      navbarScroll
      expand="lg"
      expanded={menuExpanded}
      onToggle={(expanded) => setMenuExpanded(expanded)}
    >
      <Navbar.Brand>
        <Link to="/admin/events">
          <img src="/img/logo-with-text.svg" alt="» Racemap" />
        </Link>
      </Navbar.Brand>
      <Flex align="center" gap={5}>
        {isCollapsed && <AddTrackingMapButton compact />}
        <Navbar.Toggle aria-controls="navbar-collapse" as={Button} />
      </Flex>

      <Navbar.Collapse>
        <Flex justify="space-between" vertical={isCollapsed} className="navbar-menu">
          <Space className="header-links">
            <AdminHeaderLinks />
            {!isCollapsed && <AddTrackingMapButton />}
          </Space>
          <UserLinks isCollapsed={isCollapsed} />
        </Flex>
      </Navbar.Collapse>
    </StyledNavbar>
  );
}

const StyledNavbar = styled(Navbar)`
  height: 50px;
  padding: 0;

  .ant-flex.navbar-menu {
    width: 100%;
  }

  .navbar-brand {
    margin: 0 15px;
    img {
      height: 50px;
    }
  }

  .navbar-toggler {
    margin-right: 15px;
    border: 1px solid #36739a;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(54, 115, 154, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    :hover {
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
  }

  .navbar-toggler,.add-tracking-map-button {
    &:hover {
      background-color: #36739a !important;
      color: white !important;
    }
  }

  .navbar-nav .nav-link {
    color: ${RacemapColors.DarkDarkGray};
  }

  .active {
    text-shadow: 0 0 1px ${RacemapColors.DarkDarkGray};
  }

  .nav-item .dropdown-item.active {
    color: ${RacemapColors.DarkDarkGray};
    background-color: ${RacemapColors.CloudBlue};
  }

  a#user.dropdown-toggle {
    margin-right: 10px;
  
    ::after {
      display: none;
    }
  }

  @media (max-width: ${CollapseBreakpoint}px) {
    .navbar-collapse {
      background-color: ${RacemapColors.LightLightGray};
      padding: 5px;
      border-bottom: 2px solid ${RacemapColors.PaleBlue};

      .nav-link {
        padding-inline: 10px;

        :hover {
          background-color: ${RacemapColors.CloudBlue};
          color: black;
        }
        :not(:last-of-type) {
          border-bottom: 1px solid ${RacemapColors.LightGray};
        }
      }
    }

    .header-links .ant-space-item {
      width: 100%;  
    }
  }
`;
