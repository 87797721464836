import { CheckboxValues } from '../Checkbox';

export function getSubPathes(
  pathes: ReadonlySet<string> | undefined,
  prefix: string,
): Array<string> {
  if (pathes == null) return [];
  return Array.from(pathes.values()).filter((p) => p.startsWith(`${prefix}/`));
}

export const getLastLevelPathes = (pathes: ReadonlyArray<string>): Array<string> => {
  const pathesArray = [...pathes];
  const lastLevelPathes = [];
  for (const path of pathesArray) {
    if (pathesArray.find((p) => p.startsWith(`${path}/`)) != null) continue;
    lastLevelPathes.push(path);
  }

  return lastLevelPathes;
};

export const getSelectionValue = (
  pathSelected: boolean,
  selectedSubPathes: Array<string>,
  lastLevelPathes: Array<string>,
): CheckboxValues => {
  if (
    pathSelected ||
    (selectedSubPathes.length > 0 && selectedSubPathes.length === lastLevelPathes.length)
  ) {
    return CheckboxValues.CHECKED;
  } else if (selectedSubPathes.length > 0) {
    return CheckboxValues.PARTIAL_CHECKED;
  }

  return CheckboxValues.NOT_CHECKED;
};
