import styled from '@emotion/styled';
import { Immutable } from 'immer';
import React, { FC, useEffect, useReducer } from 'react';
import { useControlled } from '../../../lib/customHooks';
import { Preview } from '../DragLayer';
import { DragLayer } from '../DragLayer/DragLayer';
import { CollapseState } from './CollapseButton';
import {
  TreeCtx,
  TreeDispatchCtx,
  TreeReducer,
  defaultDragItemTarget,
  initialState,
  treeStateReducers,
} from './Context';
import { type Props as TreeItemProps, TreeItemElements } from './Node';

export type Selection = Immutable<Set<string>>;
export type Entries = Set<string>;

export interface DragItem {
  pathes: Set<string>;
}

export interface Props {
  selection?: Selection;
  onSelectionChange?: (newSelection: Selection) => void;
  children?: TreeItemElements;
  withLines?: boolean;
  collapsable?: boolean;
  isDraggable?: boolean;
  dragItemTarget?: string;
  renderDragPreview?: (item: DragItem) => React.ReactNode;
}

export const Tree: FC<Props> = ({
  children,
  withLines = false,
  selection,
  onSelectionChange,
  isDraggable = false,
  collapsable = false,
  dragItemTarget,
  renderDragPreview = defaultRenderDragPreview,
}) => {
  const [selectionValue, handleSelectionChange] = useControlled({
    controlledValue: selection,
    onChange: onSelectionChange,
    name: 'tree-selection',
    initialValue: new Set<string>(),
  });
  const [state, dispatch] = useReducer<TreeReducer>(treeStateReducers, {
    ...initialState,
    selection: selectionValue,
  });

  useEffect(() => {
    dispatch({
      type: 'setSelection',
      selection: new Set(selectionValue),
    });
  }, [selectionValue]);

  useEffect(() => {
    dispatch({
      type: 'setTreeDraggable',
      isDraggable,
      dragItemTarget,
    });
  }, [isDraggable, dragItemTarget]);

  useEffect(() => {
    if (
      JSON.stringify(Array.from(selectionValue)) !== JSON.stringify(Array.from(state.selection))
    ) {
      handleSelectionChange(state.selection);
    }
  }, [state]);

  return (
    <Container>
      {isDraggable && (
        <DragLayer
          targetItemType={dragItemTarget || defaultDragItemTarget}
          renderPreview={renderDragPreview}
        />
      )}
      <TreeCtx.Provider value={state}>
        <TreeDispatchCtx.Provider value={dispatch}>
          {React.Children.map(children, (c, i) => {
            if (c == null) return undefined;
            const lastItemOfBranch = Array.isArray(children) && i === children.length - 1;

            return React.cloneElement<TreeItemProps>(c, {
              key: `/${c.props.itemKey}`,
              selectable:
                (c.props.selectable != null ? c.props.selectable : true) && selectionValue != null,
              _level: 1,
              _withLines: withLines,
              _lastItemOfBranch: lastItemOfBranch,
              _collapsable: collapsable,
              _parentTreeItemInfos: [
                {
                  key: '/',
                  path: '',
                  lastItemOfBranch,
                  collapseState: CollapseState.Open,
                },
              ],
            });
          })}
        </TreeDispatchCtx.Provider>
      </TreeCtx.Provider>
    </Container>
  );
};

const Container = styled.div``;
const defaultRenderDragPreview = (item: DragItem) => <Preview>{item.pathes.size} Fields</Preview>;
