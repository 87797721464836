import { DatePicker } from 'antd';
import type { DateTime } from 'luxon';
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';
import React from 'react';
import { calculateDisabledDate } from './calculateDisabledDate';
import { calculateDisabledTime } from './calculateDisabledTime';

const CustomDatePicker = DatePicker.generatePicker<DateTime>(luxonGenerateConfig);
type RangePickerProps = React.ComponentProps<typeof CustomDatePicker>;

type Props = RangePickerProps & {
  minDate?: DateTime;
};

const DatePickerComponent: React.FC<Props> = ({ minDate, ...props }) => (
  <CustomDatePicker
    disabledTime={minDate ? (current) => calculateDisabledTime(current, minDate) : undefined}
    disabledDate={minDate ? (current) => calculateDisabledDate(current, minDate) : undefined}
    {...props}
  />
);

export default DatePickerComponent;
