import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import classNames from 'classnames';
import { Immutable } from 'immer';
import { FC, PropsWithChildren } from 'react';
import { useDrop } from 'react-dnd';
import { useTrackers } from '../../lib/customHooks';

export interface TrackerDropItem {
  itemType: 'total-trackers' | 'sample-trackers';
  data: { ids: Immutable<Set<string>> };
}
export interface FilterDropItem {
  itemType: 'filter-tree';
  data: { pathes: Immutable<Set<string>> };
}

interface DropZoneProps {
  acceptedElementIds: Array<string>;
  withIsOver?: boolean;
  onDrop: (item: TrackerDropItem | FilterDropItem) => void;
}

export const TrackerDropZone: FC<PropsWithChildren<DropZoneProps>> = ({
  children,
  acceptedElementIds,
  withIsOver = false,
  onDrop,
}) => {
  const trackers = useTrackers();

  const [{ isOver }, drop] = useDrop<
    Partial<TrackerDropItem['data']> & Partial<FilterDropItem['data']>,
    unknown,
    { isOver: boolean }
  >(
    () => ({
      accept: acceptedElementIds,
      drop: (item, monitor) => {
        const itemType = monitor.getItemType();
        if (itemType == null) return;
        if (item.ids != null) {
          onDrop({
            itemType: itemType as 'total-trackers' | 'sample-trackers',
            data: { ids: item.ids },
          });
        }
        if (item.pathes != null) {
          onDrop({
            itemType: itemType as 'filter-tree',
            data: { pathes: item.pathes },
          });
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [trackers],
  );

  return (
    <DropZone ref={drop} className={classNames({ isOver })}>
      {children}
      {isOver && withIsOver && <Overlay />}
    </DropZone>
  );
};

const DropZone = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Overlay = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  position: absolute;
  float: left;
  box-sizing: content-box;
  border: 3px solid ${RacemapColors.Gray};
  background-color: ${RacemapColors.LightGray}50;
  border-radius: 3px;
`;
