// hooks.ts
import { useCallback, useEffect, useRef, useState } from 'react';

type UseControlledConfig<Value, OnChangeType> = {
  controlledValue?: Value;
  initialValue: Value;
  name: string;
  onChange?: OnChangeType;
};

/**
 * A custom hook that handles the logic for managing controlled and uncontrolled components.
 *
 * @template Value - The type of the value being controlled.
 * @param {object} config - An object containing the configuration options for the hook.
 * @param {Value | undefined} config.controlledValue - The controlled value to use. If this is defined, the component is controlled.
 * @param {Value} config.initialValue - The initial value to use for the component. This is only used if the component is uncontrolled.
 * @param {string} config.name - The name of the component. Used for warning messages.
 * @param {(newValue: Value) => void | undefined} config.onChange - An optional callback that is called when the controlled value changes.
 * @returns {[Value, (newValue: Value) => void]} - A tuple containing the current value and a function to update the value. If the component is controlled, the function will call the onChange callback. Otherwise, it will update the internal state of the component.
 */
export const useControlled = <
  Value,
  OnChangeType extends (newValue: Value, ...additionalParams: Array<any>) => void = (
    newValue: Value,
    ...additionalParams: Array<any>
  ) => void,
>({
  controlledValue,
  initialValue,
  name,
  onChange,
}: UseControlledConfig<Value, OnChangeType>): readonly [Value, OnChangeType] => {
  const isControlled = onChange !== undefined;
  const { current: origIsControlled } = useRef(isControlled);
  const [internalValue, setInternalValue] = useState(controlledValue || initialValue);
  const { current: origInitialValue } = useRef(initialValue);
  const value = (origIsControlled ? controlledValue : internalValue) as Value;

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      if (origIsControlled !== isControlled) {
        console.warn(
          `"${name}" is changed from ${
            origIsControlled ? 'uncontrolled to controlled' : 'controlled to uncontrolled'
          }.`,
        );
      }
    }
  }, [origIsControlled, isControlled, name]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      if (!origIsControlled && origInitialValue !== initialValue) {
        console.warn(
          `"${name}" is changing its defaultValue after being initialized. Make "${name}" a controlled component instead.`,
        );
      }
    }
  }, [origInitialValue, initialValue, name, origIsControlled]);

  const setValueIfUncontrolled = useCallback(
    (newValue: Value, ...additionalParams: Array<any>) => {
      if (!origIsControlled) {
        setInternalValue(newValue);
      } else {
        onChange?.(newValue, ...additionalParams);
      }
    },
    [origIsControlled],
  ) as OnChangeType;

  useEffect(() => {
    if (isControlled) return;
    setValueIfUncontrolled(controlledValue as Value);
  }, [controlledValue]);

  return [value, setValueIfUncontrolled] as const;
};
