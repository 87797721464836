import { Immutable } from 'immer';
import { ModulesStates, Targets } from '../consts/events';
import { RacemapEvent } from '../types/events';
import { EventTrackObject, SplitObject } from '../types/geos';
import { hasShadowtrack } from './map';
import { isDefined, isRacemapTrack, isSplitPoint } from './validation';

export function calculateActivatedPeriods(
  dateEnd: Date | string | null,
  dateStart: Date | string | null,
): number {
  const date1 = dateEnd != null ? new Date(dateEnd) : new Date();
  const date2 = dateStart != null ? new Date(dateStart) : new Date();
  const years = new Date(date1).getFullYear() - new Date(date2).getFullYear();
  const month = new Date(date1).getMonth() - new Date(date2).getMonth();
  const days = new Date(date1).getDate() - new Date(date2).getDate();
  const activatedPeriods = Math.ceil((years * 12 + month + (days > 0 ? 1 : 0)) / 3);
  return activatedPeriods;
}

export function currentEventTimes(
  event?: Immutable<RacemapEvent> | null,
): [number | undefined, number | undefined] {
  if (!event?.startTime || !event?.endTime) return [undefined, undefined];
  const startTime = Date.parse(event.startTime);
  const endTime = Date.parse(event.endTime);
  const eventDuration = endTime - startTime;
  const repeatTimeOffset = event.isRepeating
    ? Math.floor((Date.now() - startTime) / eventDuration) * eventDuration
    : 0;
  return [startTime + repeatTimeOffset, endTime + repeatTimeOffset];
}

type CustomerIdMapping = {
  creatorCuId?: string;
  editorCuIds: Array<string>;
  additionalCuIds: Array<string>;
};

export function getRRCustomerIdsMapping(
  event: RacemapEvent | Immutable<RacemapEvent>,
): CustomerIdMapping {
  const creatorCuId = event.creator.integrations?.raceResultCustomerId || undefined;
  const editorCuIds = Array.from(event.editors.values())
    .map((e) => e.integrations?.raceResultCustomerId)
    .filter((e) => e !== creatorCuId)
    .filter(isDefined);
  const additionalCuIds = event.integrations.raceresult.customerIds.filter(
    (id) => id !== creatorCuId && !editorCuIds.includes(id),
  );

  return {
    creatorCuId,
    editorCuIds,
    additionalCuIds,
  };
}

export function getRRCustomerIds(
  event: RacemapEvent | Immutable<RacemapEvent> | null,
): Array<string> {
  if (event == null) return [];
  const mapping = getRRCustomerIdsMapping(event);

  return [mapping.creatorCuId, ...mapping.editorCuIds, ...mapping.additionalCuIds].filter(
    isDefined,
  );
}

export function eventHasVirtDuration(event: RacemapEvent | Immutable<RacemapEvent>) {
  return (
    (!hasShadowtrack(event) || event.modules.projection.enabled) &&
    event.modules.timing.target === Targets.DURATION &&
    event.modules.timing.duration != null
  );
}

export function getTimekeepingSplits(
  event?: Immutable<RacemapEvent> | null,
): Immutable<Array<SplitObject>> {
  return getSplitsOfTrack(event).filter((split) => split.properties.timekeeping);
}

export function getSplitsOfTrack(
  event?: Immutable<{ geo: RacemapEvent['geo'] }> | null,
): Array<SplitObject> {
  if (
    event == null ||
    event.geo == null ||
    event.geo.shadowtrackId == null ||
    event.geo.features == null
  )
    return [];
  const trackId = event.geo.shadowtrackId;
  return event.geo.features
    .filter(isSplitPoint)
    .filter((f) => f.properties.lineStringId === trackId)
    .sort((a, b) =>
      a.properties.offset == null
        ? 1
        : b.properties.offset == null
        ? 1
        : a.properties.offset - b.properties.offset,
    );
}

export function getSubReaderIds(splits: Immutable<Array<SplitObject>>): Set<string> {
  return new Set(
    splits.reduce<Array<string>>((prev, s) => {
      if (s.properties.readerIds == null) return prev;
      return [...prev, ...s.properties.readerIds];
    }, []),
  );
}

export function getTracksOfEvent(event: Immutable<RacemapEvent>): Array<EventTrackObject> {
  const features = event.geo?.features;
  if (features == null) return [];

  return features.filter(isRacemapTrack);
}

// modules helpers

export function isActivated(modules?: { state?: ModulesStates; enabled?: boolean }): boolean {
  if (modules == null) return false;
  if (modules.state != null) return modules.state === ModulesStates.ACTIVATED;
  return !!modules.enabled;
}

export function isEnabled(modules: { state?: ModulesStates }): boolean {
  if (modules.state !== null) return modules.state === ModulesStates.ENABLED;
  return false;
}
