import { Immutable } from 'immer';
import moment from 'moment';

import { TrackerMessage, TrackerMessageOverview } from '@racemap/sdk/schema/trackers';
import { OneMinuteInMillis } from '@racemap/utilities/consts/time';
import { TRACKER_TYPES, TrackerActivationType } from '@racemap/utilities/consts/trackers';
import { isTrackerOnline } from '@racemap/utilities/functions/trackers';
import type { ManagedTracker } from '../../../store/trackers/trackers_reducers';

export enum Filters {
  Tags = 'tags',
  Events = 'events',
  Online = 'online',
  TrackerType = 'trackerType',
  Messages = 'messages',
  Location = 'location',
  Battery = 'battery',
  Activation = 'activation',
  Updated = 'updated',
  Logistics = 'logistics',
  Users = 'users',
}

export enum MessageFilters {
  NO_NEW_MESSAGES = 'noNewMessages',
  WITH_ERROR_MESSAGES = 'errorCount',
  WITH_PENDING_MESSAGES = 'pendingCount',
  WITH_SCHEDULED_MESSAGES = 'scheduledCount',
}

export enum BatteryFilters {
  FromEmtpyTo15 = 'fromEmptyTo15',
  From15To25 = 'from15To25',
  From25To50 = 'from25To50',
  From50To75 = 'from50To75',
  From75To90 = 'from75To90',
  From90ToFull = 'from90ToFull',
}

export enum LocationFilters {
  NoLocation = 'NoLocation',
  Cell = 'Cell',
  NoFix = 'NoFix',
  Fix = 'Fix',
}

export enum UpdatedAtFilters {
  Recently = 'recently',
  From1MinuteTo5Minutes = 'from1MinuteTo5Minutes',
  From5MinutesTo30Minutes = 'from5MinutesTo30Minutes',
  MoreThan30Minutes = 'moreThan30Minutes',
}

export enum OnlineFilters {
  Offline = 'Offline',
  Registered = 'Registered',
  Data = 'Data',
  Connected = 'Connected',
}

export enum ActivationFilters {
  ARCHIVED = 'archived',
  UNUSED = 'unused',
  USED = 'used',
  RACEMAPTRACKER = 'racemapTracker',
}

export enum LogisticsFilters {
  IN_STOCK = 'inStock',
  OUT_OF_STOCK = 'outOfStock',
}

export enum UserRoleFilters {
  OWNER = 'owner',
  EDITOR = 'editor',
  BORROWER = 'borrower',
}

export const isInBatteryFilterRange = (filter: BatteryFilters, value?: number): boolean => {
  if (value == null) return false;
  switch (filter) {
    case BatteryFilters.FromEmtpyTo15:
      return value <= 15;
    case BatteryFilters.From15To25:
      return value > 15 && value <= 25;
    case BatteryFilters.From25To50:
      return value > 25 && value <= 50;
    case BatteryFilters.From50To75:
      return value > 50 && value <= 75;
    case BatteryFilters.From75To90:
      return value > 75 && value <= 90;
    case BatteryFilters.From90ToFull:
      return value > 90;
    default:
      return false;
  }
};

export const getBatteryFilterCategory = (value: number): BatteryFilters => {
  if (value <= 15) {
    return BatteryFilters.FromEmtpyTo15;
  } else if (value > 15 && value <= 25) {
    return BatteryFilters.From15To25;
  } else if (value > 25 && value <= 50) {
    return BatteryFilters.From25To50;
  } else if (value > 50 && value <= 75) {
    return BatteryFilters.From50To75;
  } else if (value > 75 && value <= 90) {
    return BatteryFilters.From75To90;
  } else if (value > 75 && value <= 90) {
    return BatteryFilters.From75To90;
  } else {
    return BatteryFilters.From90ToFull;
  }
};

export const isInUpdatedAtTimeRange = (filter: UpdatedAtFilters, datetime?: Date): boolean => {
  const duration = Date.now() - (datetime?.valueOf() || NaN);
  if (isNaN(duration)) return false;

  switch (filter) {
    case UpdatedAtFilters.Recently:
      return duration <= 1 * OneMinuteInMillis;
    case UpdatedAtFilters.From1MinuteTo5Minutes:
      return duration > 1 * OneMinuteInMillis && duration <= 5 * OneMinuteInMillis;
    case UpdatedAtFilters.From5MinutesTo30Minutes:
      return duration > 5 * OneMinuteInMillis && duration <= 30 * OneMinuteInMillis;
    case UpdatedAtFilters.MoreThan30Minutes:
      return duration > 30 * OneMinuteInMillis;
    default:
      return false;
  }
};

export const getUpdatedAtFilter = (datetime?: Date): UpdatedAtFilters => {
  const duration = Date.now() - (datetime?.valueOf() || NaN);
  if (isNaN(duration)) return UpdatedAtFilters.MoreThan30Minutes;

  if (duration <= 1 * OneMinuteInMillis) {
    return UpdatedAtFilters.Recently;
  } else if (duration > 1 * OneMinuteInMillis && duration <= 5 * OneMinuteInMillis) {
    return UpdatedAtFilters.From1MinuteTo5Minutes;
  } else if (duration > 5 * OneMinuteInMillis && duration <= 30 * OneMinuteInMillis) {
    return UpdatedAtFilters.From5MinutesTo30Minutes;
  } else {
    return UpdatedAtFilters.MoreThan30Minutes;
  }
};

export const getLocationState = (tracker: Immutable<ManagedTracker>): LocationFilters => {
  const { lastTime, accuracy } = tracker.lastGeo;

  if (lastTime == null) return LocationFilters.NoLocation;

  if (accuracy == null || accuracy === -1) {
    return moment(lastTime).isBefore(moment().subtract(5, 'minutes'))
      ? LocationFilters.NoFix
      : LocationFilters.Fix;
  }
  if (accuracy === 0) return LocationFilters.NoFix;

  return LocationFilters.Fix;
};

export const getLocationRank = (locationState: LocationFilters): number => {
  return locationStateNumberMapping[locationState];
};

const locationStateNumberMapping: Record<LocationFilters, number> = {
  [LocationFilters.NoLocation]: 0,

  [LocationFilters.Cell]: 1,
  [LocationFilters.NoFix]: 2,
  [LocationFilters.Fix]: 3,
};

export const getOnlineStateValue = (trackerState: ManagedTracker['state']): OnlineFilters => {
  if (isTrackerOnline(trackerState)) {
    return OnlineFilters.Connected;
  }
  return OnlineFilters.Offline;
};

export const getOnlineStateRank = (trackerState: ManagedTracker['state']): number => {
  if (isTrackerOnline(trackerState)) {
    return 3;
  }
  return 0;
};

export function getActivationState(trackerActivation: {
  type: TrackerActivationType;
  paidUntil?: Date | null | undefined;
}): ActivationFilters {
  switch (trackerActivation.type) {
    case TrackerActivationType.ARCHIVED:
      return ActivationFilters.ARCHIVED;
    case TrackerActivationType.RACEMAPTRACKER:
      return ActivationFilters.RACEMAPTRACKER;
    default:
      if (
        trackerActivation.paidUntil == null ||
        new Date(trackerActivation.paidUntil).getTime() < Date.now()
      ) {
        return ActivationFilters.UNUSED;
      }
      return ActivationFilters.USED;
  }
}

export function isUserInTrackerRole(
  userId: string,
  userRole: UserRoleFilters,
  tracker: Immutable<ManagedTracker>,
): boolean {
  return (
    (userRole === UserRoleFilters.OWNER && tracker.owner.equals(userId)) ||
    (userRole === UserRoleFilters.EDITOR &&
      tracker.editors.some((editor) => editor.equals(userId))) ||
    (userRole === UserRoleFilters.BORROWER &&
      tracker.borrowers.some((borrower) => borrower.id.equals(userId)))
  );
}

export enum TrackerMessageState {
  UNKNOWN = 'UNKNOWN',
  NO_NEW_MESSAGE = 'NO_NEW_MESSAGE',
  REVOKED = 'REVOKED',
  REQUESTED = 'REQUESTED',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
  OK = 'OK',
  TIMEOUT = 'TIMEOUT',
  ERROR = 'ERROR',
}

const FilterMap = {
  [Filters.Battery]: Array.from(Object.values(BatteryFilters)),
  [Filters.Location]: Array.from(Object.values(LocationFilters)),
  [Filters.Messages]: Array.from(Object.values(TrackerMessageState)),
  [Filters.Online]: Array.from(Object.values(OnlineFilters)),
  [Filters.TrackerType]: Array.from(Object.values(TRACKER_TYPES)),
  [Filters.Activation]: Array.from(Object.values(ActivationFilters)),
  [Filters.Logistics]: Array.from(Object.values(LogisticsFilters)),
  [Filters.Updated]: Array.from(Object.values(UpdatedAtFilters)),
  [Filters.Events]: [],
  [Filters.Tags]: [],
  [Filters.Users]: [],
};

export function getEmptyFilterCounts(): Map<Filters, Map<string, number>> {
  return new Map(
    Object.values(Filters).map((f) => [f, new Map(FilterMap[f].map((fE) => [fE, 0]))]),
  );
}

export const hasNoNewMessage = (
  messages: Immutable<Array<TrackerMessage | TrackerMessageOverview>>,
): boolean => {
  if (messages.length === 0) return true;
  const messagesSorted = [...messages].sort(
    (a, b) => b.plannedAt.valueOf() - a.plannedAt.valueOf(),
  );

  if (messagesSorted[0].closeAfter.valueOf() < Date.now()) return true;

  return false;
};
