import { ObjectId } from '@racemap/sdk/schema/base';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { RacemapGeoTypes } from '@racemap/utilities/types/geos';
import { Button, ButtonProps, Space } from 'antd';
import React, { CSSProperties, FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { ParticipantTabKeys } from '../../EventEditor/ParticipantTab/ParticipantTab';
import { PredictionTabKeys } from '../../EventEditor/PredictionTab/PredictionTab';
import {
  IconBook,
  IconCalculator,
  IconChartLine,
  IconMap,
  IconMoney,
  IconPrediction,
  IconRunner,
  IconStripe,
  IconUser,
  IconWallet,
} from '../../Icon';

interface Props {
  link: string;
  label: string;
  title?: string;
  icon: React.ReactNode;
  as: 'button' | 'href' | 'icon';
  openInNewTab?: boolean;
  style?: CSSProperties;
  buttonType?: ButtonProps['type'];
}

/**
 * ### Link Component
 * can be used as a button, a href or an icon
 * @param param0
 * @returns
 */
export const RawLink: FC<Props> = ({
  link,
  label,
  title,
  icon,
  as,
  style,
  buttonType = 'default',
  openInNewTab = false,
}) => {
  const isExternal = link.startsWith('http');
  const history = useHistory();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (isExternal || openInNewTab) {
      window.open(link, '_newtab');
    } else {
      history.push(link);
    }
  };

  switch (as) {
    case 'button':
      return (
        <Button type={buttonType} onClick={handleClick} title={label} style={style}>
          <Space size={5}>
            {icon}
            {label}
          </Space>
        </Button>
      );
    case 'href':
      return (
        <Button
          type="link"
          title={title}
          onClick={handleClick}
          style={{
            padding: '0 2px',
            color: RacemapColors.PaleBlue,
            height: '1rem',
            ...style,
          }}
        >
          <Space size={3} align="center">
            {icon}
            {label}
          </Space>
        </Button>
      );
    case 'icon':
      return (
        <Button
          type="link"
          title={`${label}: ${title}`}
          onClick={handleClick}
          style={{ padding: '0 2px', color: RacemapColors.PaleBlue, height: '1rem', ...style }}
        >
          {icon}
        </Button>
      );
  }
};

export const StripeLink: FC<
  Partial<Props> & { stripeId?: string; as?: Props['as']; dataType?: 'customers' | 'invoices' }
> = ({ stripeId, dataType = 'customers', as = 'href', ...props }) => {
  if (stripeId == null) return <></>;
  const isTestEnvironment =
    window.location.hostname === 'localhost' || window.location.hostname === 'gears.racemap.com';

  return (
    <RawLink
      link={`https://dashboard.stripe.com/${isTestEnvironment ? 'test/' : ''}customers/${stripeId}`}
      label="Dashboard"
      title={`Open the ${dataType} dashboard in stripe!`}
      icon={<IconStripe />}
      as={as}
      {...props}
    />
  );
};

export const BillingLink: FC<
  Partial<Props> & { userId: string; as?: Props['as']; label?: string }
> = ({ userId, as = 'href', label = 'Billing', ...props }) => {
  return (
    <RawLink
      link={`/admin/account/${userId}/billing`}
      label={label}
      title="Show the billing page."
      icon={<IconWallet />}
      as={as}
      {...props}
    />
  );
};

export const AccountLink: FC<Partial<Props> & { userId: string | ObjectId; as?: Props['as'] }> = ({
  userId,
  as = 'href',
  ...props
}) => {
  return (
    <RawLink
      link={`/admin/account/${userId.toString()}`}
      label="Account"
      title="Show the account of the user!"
      icon={<IconUser />}
      as={as}
      {...props}
    />
  );
};

export const DocuLink: FC<Partial<Props> & { path: string; as?: Props['as'] }> = ({
  path,
  as = 'href',
  ...props
}) => {
  return (
    <RawLink
      link={`https://docs.racemap.com/${path}`}
      label="Documentation"
      title="Open the documentation in a new tab!"
      icon={<IconBook />}
      as={as}
      {...props}
    />
  );
};

export const MapEditorLink: FC<
  Partial<Props> & {
    eventId: string;
    geoElementType?: RacemapGeoTypes;
    geoElementId?: string;
    as?: Props['as'];
  }
> = ({ eventId, geoElementType, geoElementId, as = 'href', ...props }) => {
  let link = `/admin/events/${eventId}/map`;
  if (geoElementType != null) {
    link += `/${geoElementType}`;
    if (geoElementId != null) {
      link += `/${geoElementId}`;
    }
  }

  return (
    <RawLink
      link={link}
      label="Map Editor"
      title="Open the map editor!"
      icon={<IconMap />}
      as={as}
      {...props}
    />
  );
};

export const ParticipantListLink: FC<
  Partial<Props> & {
    eventId: string;
    tab?: ParticipantTabKeys;
    as?: Props['as'];
  }
> = ({ eventId, tab = 'list', as = 'href', ...props }) => {
  let link = `/admin/events/${eventId}/participants`;
  if (tab != null) {
    link += `/${tab}`;
  }

  return (
    <RawLink
      link={link}
      label="Participant List"
      title="Open the participant list!"
      icon={<IconRunner />}
      as={as}
      {...props}
    />
  );
};

export const PredictionTabLink: FC<
  Partial<Props> & {
    eventId: string;
    tab?: PredictionTabKeys;
    as?: Props['as'];
  }
> = ({ eventId, tab = 'list', as = 'href', ...props }) => {
  let link = `/admin/events/${eventId}/prediction`;
  if (tab != null) {
    link += `/${tab}`;
  }

  return (
    <RawLink
      link={link}
      label="Prediction"
      title="Open the prediction tab!"
      icon={<IconPrediction />}
      as={as}
      {...props}
    />
  );
};

export const AnalyticsTabLink: FC<
  Partial<Props> & {
    eventId: string;
    as?: Props['as'];
  }
> = ({ eventId, as = 'href', ...props }) => {
  const link = `/admin/events/${eventId}/analytics`;

  return (
    <RawLink
      link={link}
      label="Analytics"
      title="Open the analytics tab!"
      icon={<IconChartLine />}
      as={as}
      {...props}
    />
  );
};

export const CostCalculatorLink: FC<
  Partial<Props> & { product?: 'events' | 'tracker-buy' | 'tracker-rent'; as?: Props['as'] }
> = ({ product = 'events', as = 'href', ...props }) => {
  return (
    <RawLink
      link={`/cost-calculator/${product}`}
      label="Cost Calculator"
      title="Show the cost calculator!"
      icon={<IconCalculator />}
      as={as}
      {...props}
    />
  );
};

export const PricingLink: FC<Partial<Props> & { as?: Props['as'] }> = ({
  as = 'href',
  ...props
}) => {
  return (
    <RawLink
      link="https://racemap.com/pricing"
      label="Documentation"
      title="Open the documentation in a new tab!"
      icon={<IconMoney />}
      as={as}
      {...props}
    />
  );
};
