import { ObjectId, ObjectIdSchema } from '@racemap/sdk/schema/base';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { Immutable } from 'immer';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useStore } from '../../../store/reducers';
import { TreeNode, TreeNodeProps } from '../../BasicComponents/Tree';
import { AddTrackerToEvent } from '../AddTrackerToEvent/AddTrackerToEvent';
import { TrackerDropItem, TrackerDropZone } from '../TrackerDropZone';
import { Filters } from './FilterTreeFunctions';

export const NO_EVENT_KEY = 'none';

interface Props extends TreeNodeProps {
  label: string;
}

export const EventsNode: FC<Props> = (props) => {
  const events = useStore((s) => s.trackers.events);
  const filterCounts = useStore((s) => s.trackers.filterCounts);
  const eventIds = [NO_EVENT_KEY, ...Array.from(events.values()).map((e) => e.id)];
  const counts = filterCounts.get(Filters.Events);

  return (
    <TreeNode {...props}>
      {eventIds.map((eId) => {
        const event = events.get(eId);

        return (
          <TreeNode
            key={eId}
            label={<EventLabel event={event} />}
            itemKey={eId}
            count={counts?.get(eId)}
            searchValue={`${event?.name || ''}_${eId}`}
          />
        );
      })}
    </TreeNode>
  );
};

const EventLabel: FC<{ event?: Immutable<RacemapEvent> }> = ({ event }) => {
  const [show, setShow] = useState(false);
  const [trackerIds, setTrackerIds] = useState<Array<ObjectId>>([]);

  if (event == null) return <>{NO_EVENT_KEY}</>;

  return (
    <TrackerDropZone
      acceptedElementIds={['sample-trackers', 'total-trackers']}
      withIsOver={true}
      onDrop={(item) => {
        setTrackerIds(
          Array.from((item as TrackerDropItem).data.ids).map((id) => ObjectIdSchema.parse(id)),
        );
        setShow(true);
      }}
    >
      {event.name || event.id}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add tracker to an event</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AddTrackerToEvent
            trackerIds={trackerIds}
            event={event}
            onAddToEvent={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    </TrackerDropZone>
  );
};
