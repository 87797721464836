import styled from '@emotion/styled';
import React, { FC, useEffect } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';

interface Props<DragObject = any> {
  onDraggingStateChange?: (newState: boolean) => void;
  targetItemType: string;
  renderPreview: (item: DragObject) => React.ReactNode;
}

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x + 10}px, ${y + 10}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const DragLayer: FC<Props> = ({ onDraggingStateChange, targetItemType, renderPreview }) => {
  const { itemType, item, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  useEffect(() => {
    onDraggingStateChange?.(isDragging);
  }, [isDragging]);

  function renderItem() {
    switch (itemType) {
      case targetItemType:
        return renderPreview(item);
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }
  return (
    <Layer>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </Layer>
  );
};

const Layer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
