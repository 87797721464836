import { StripeProducts } from '@racemap/sdk/schema/billing';
import { clamp } from '@racemap/utilities/math';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import Stripe from 'stripe';
import { formatNumber, formatPrice } from '../../misc/pricing';

interface Props {
  product: StripeProducts;
  price?: Stripe.Price;
  quantity: number;
  getPricePerUnit: (product: StripeProducts, tierIndex?: number) => number | undefined;
  getProductPrice: (number: StripeProducts, count: number, tierIndex?: number) => number;
  lastEntry?: boolean;
}

export const ProductCostRow: FC<Props> = ({
  product,
  quantity,
  price,
  getPricePerUnit,
  getProductPrice,
  lastEntry,
}) => {
  const packageSize = parseInt(price?.metadata?.packageSize || '', 10) || 1;
  const quantityFactor = 1 / packageSize;

  if (quantity === 0) return null;
  if (price?.billing_scheme !== 'tiered')
    return (
      <>
        <Row className="my-2">
          <Col sm={6} xs={10}>
            {productLabels[product]}
          </Col>
          <Col sm={2} xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {formatNumber(quantity)}
          </Col>
          <Col sm={2} xs={4}>
            x {formatPrice(getPricePerUnit(product) || 0)}
          </Col>
          <Col sm={2} xs={4}>
            = {formatPrice(getProductPrice(product, quantity * quantityFactor))}
          </Col>
        </Row>
        {!lastEntry && <hr />}
      </>
    );

  if (price.tiers == null) throw new Error('Tiered pricing without tiers');

  let tierDownBorder = 0;
  let tierUpBorder = 0;
  let tierQuantity = 0;
  let chargedQuantity = 0;

  return (
    <>
      <Row className="my-2">
        <Col sm={6} xs={10}>
          {productLabels[product]}
        </Col>
      </Row>
      {price.tiers.map((tier, index) => {
        tierDownBorder = tierUpBorder;
        tierUpBorder = (tier.up_to || Infinity) / quantityFactor;
        tierQuantity = clamp(0, quantity - chargedQuantity, tierUpBorder - tierDownBorder);
        chargedQuantity += tierQuantity;

        if (tierQuantity === 0) return null;

        return (
          <Row className="my-2" key={`${price.id}_${tier.up_to}`}>
            <Col sm={6} xs={10}>
              {`${tierDownBorder > 0 ? formatNumber(tierDownBorder) : 0} - ${
                tierUpBorder === Infinity ? '∞' : formatNumber(tierUpBorder)
              }`}
            </Col>
            <Col sm={2} xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {formatNumber(tierQuantity * quantityFactor)}
              {quantityFactor !== 1 ? ` x ${formatNumber(1 / quantityFactor)}` : ''}
            </Col>
            <Col sm={2} xs={4}>
              x {formatPrice(getPricePerUnit(product, index) || 0)}
            </Col>
            <Col sm={2} xs={4}>
              = {formatPrice(getProductPrice(product, tierQuantity, index))}
            </Col>
          </Row>
        );
      })}
      {!lastEntry && <hr />}
    </>
  );
};

const productLabels: Record<StripeProducts, string> = {
  [StripeProducts.PAGE_VIEW]: 'Loads',
  [StripeProducts.STARTER]: 'Devices',
  [StripeProducts.KEY]: 'Key',
  [StripeProducts.SPONSOR_ADDON]: 'Sponsor Addon',
  [StripeProducts.TIMING_ADDON]: 'Timing Addon',
  [StripeProducts.MONITOR_ADDON]: 'Monitor Addon',
  [StripeProducts.API_ADDON]: 'API Addon',
  [StripeProducts.SIM_CARD_ACTIVATION]: 'SIM Card Activation',
  [StripeProducts.CUSTOM_APP]: 'Custom App',
  [StripeProducts.TRACKER_MANAGEMENT]: 'Tracker Management',
  [StripeProducts.SMS]: 'SMS',
  [StripeProducts.MAP]: 'Map',
  [StripeProducts.DATA_USAGE_ZONE_1]: 'Data Usage Zone 1',
  [StripeProducts.DATA_USAGE_ZONE_2]: 'Data Usage Zone 2',
  [StripeProducts.DATA_USAGE_ZONE_3]: 'Data Usage Zone 3',
  [StripeProducts.MAP_OR_MONITOR_LOAD]: 'Map or Monitor Load',
  [StripeProducts.API_OR_LEADERBOARD_LOAD]: 'API or Leaderboard Load',
  [StripeProducts.GPS_DEVICE]: 'GPS Device',
  [StripeProducts.TRANSPONDER]: 'Transponder',
  [StripeProducts.BASE_PRICE]: 'Base Price',
};
