import { produce } from 'immer';
import { Immutable } from 'immer';
import { Reducer, createContext } from 'react';

export interface TreeContext {
  pathes: Immutable<Set<string>>;
  selection: Immutable<Set<string>>;
  treeDraggable: boolean;
  dragItemTarget: string;
}
export type TreeReducerAction = {
  type:
    | 'addPath'
    | 'removePath'
    | 'addPathToSelection'
    | 'removePathFromSelection'
    | 'setSelection'
    | 'setTreeDraggable';
  path?: string;
  selection?: Set<string>;
  isDraggable?: boolean;
  dragItemTarget?: string;
};
export type TreeReducer = Reducer<TreeContext, TreeReducerAction>;

export const TreeCtx = createContext<TreeContext | null>(null);
export const TreeDispatchCtx = createContext<React.Dispatch<TreeReducerAction> | null>(null);

export const defaultDragItemTarget = 'tree-selection';
export const initialState: TreeContext = {
  pathes: new Set(),
  selection: new Set(),
  treeDraggable: false,
  dragItemTarget: defaultDragItemTarget,
};

export const treeStateReducers: TreeReducer = (state, action) => {
  switch (action.type) {
    case 'addPath': {
      return produce(state, (s) => {
        if (action.path == null) return;
        s.pathes.add(action.path);
      });
    }
    case 'removePath': {
      return produce(state, (s) => {
        if (action.path == null) return;

        s.pathes.delete(action.path);
      });
    }
    case 'setSelection': {
      return produce(state, (s) => {
        if (action.selection == null) return;

        s.selection = action.selection;
      });
    }
    case 'setTreeDraggable': {
      return produce(state, (s) => {
        if (action.isDraggable == null) return;

        s.treeDraggable = action.isDraggable;
        s.dragItemTarget = action.dragItemTarget || defaultDragItemTarget;
      });
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
};
