import { FeatureCollection } from 'geojson';
import { MapType } from './events';

export const mapboxBaseURL = 'mapbox://styles/mapbox/';

export interface MapStyle {
  label: string;
  key: string;
}

export const mapStyles: Record<MapType, MapStyle> = {
  TERRAIN: { label: 'Terrain', key: 'outdoors-v11' },
  SATELLITE: { label: 'Satellite', key: 'satellite-v9' },
  HYBRID: { label: 'Hybrid', key: 'satellite-streets-v11' },
  ROADMAP: { label: 'Roads', key: 'streets-v11' },
  DARK: { label: 'Dark', key: 'dark-v10' },
};
export type MapStyles = typeof mapStyles;

export const EMPTY_FEATURE_COLLECTION: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

export enum SymbolTypes {
  FirstMan = '1st-man',
  FirstWoman = '1st-woman',
  SecondMan = '2nd-man',
  SecondWoman = '2nd-woman',
  ThirdMan = '3rd-man',
  ThirdWoman = '3rd-woman',
  accomodation = 'accomodation',
  AmbulanceMoving = 'ambulance-moving',
  AmbulanceStill = 'ambulance-still',
  BlackSpot = 'black-spot',
  BroomWagon = 'broom-wagon',
  CutOffDeadline = 'cut-off-deadline',
  Finish = 'finish-01',
  FinishAlternative = 'finish-02',
  FotoSection = 'foto-section',
  InformationPoint = 'information-point',
  ManEmpty = 'man-empty',
  Parking = 'parking',
  PoliceMoving = 'police-moving',
  PoliceStill = 'police-still',
  RaceDirectorMoving = 'race-director-moving',
  RaceDirectorStill = 'race-director-still',
  RefreshmentFood = 'refreshment-food',
  Registration = 'registration',
  Shower = 'shower',
  SplitTimes = 'split-times',
  SprintClassification01 = 'sprint-classification-01',
  SprintClassification02 = 'sprint-classification-02',
  SprintClassification03 = 'sprint-classification-03',
  SprintEmpty = 'sprint-empty',
  Start = 'start-01',
  StartAlternative = 'start-02',
  TrackSwitch = 'track-switch',
  TransitionZone = 'transition-zone',
  TurnArrow = 'turn-arrow-01',
  TurnArrowAlternative = 'turn-arrow-02',
  UphillClassification01 = 'uphill-classification-01',
  UphillClassification02 = 'uphill-classification-02',
  UphillClassification03 = 'uphill-classification-03',
  Water = 'water',
  WC = 'WC',
  WomanEmpty = 'woman-empty',
}
