import _ from 'lodash';
import React, { CSSProperties, Component, FC, FormEvent, useState } from 'react';
import { Button, Modal as BSModal, ModalProps as BSModalProps } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { IconExclamationTriangle, IconFail, IconQuestion } from './Icon';

type ModalState = {
  isVisible: boolean;
};

interface ModalProps extends BSModalProps {
  actionButtonLabel?: React.ReactNode;
  actionButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
  showActionButton?: boolean;
  actionButtonId?: string;
}

export default class Modal extends Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = { isVisible: false };
  }

  static defaultProps: ModalProps = {
    onClose: () => {},
    onAction: () => {},
    footer: true,
    noForm: false,
    size: 'sm',
    standalone: false,
    actionButtonLabel: 'OK',
    showActionButton: true,
  };

  state = {
    isVisible: false,
  };

  backdropRef = React.createRef();

  componentDidMount() {
    if (!this.props.standalone) {
      _.defer(() => {
        this.show();
      });
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({ isVisible: !!this.props.show });
    }
  }

  handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target === this.backdropRef.current) {
      this.handleCancelClick();
    }
  };

  handleAction = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.props.onAction();
    this.close(event);
  };

  handleCancelClick = () => {
    this.close();
  };

  close(...args: Array<any>) {
    this.setState({ isVisible: false });
    window.setTimeout(() => {
      this.props.onClose(...args);
    }, 500);
  }

  show() {
    this.setState({ isVisible: true });
  }

  render() {
    return (
      <BSModal show={this.state.isVisible} onHide={this.handleCancelClick} size={this.props.size}>
        {this.props.noForm ? (
          <>
            <BSModal.Header closeButton>
              <BSModal.Title>{this.props.title}</BSModal.Title>
            </BSModal.Header>
            <BSModal.Body>{this.props.children}</BSModal.Body>
            {this.props.footer && (
              <BSModal.Footer>
                <Button onClick={this.handleCancelClick}>
                  <IconFail /> Close
                </Button>
              </BSModal.Footer>
            )}
          </>
        ) : (
          <form onSubmit={this.handleAction}>
            <BSModal.Header closeButton>
              <BSModal.Title>{this.props.title}</BSModal.Title>
            </BSModal.Header>
            <BSModal.Body>{this.props.children}</BSModal.Body>

            {this.props.footer && (
              <BSModal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={this.handleCancelClick}
                  disabled={this.props.cancelButtonDisabled}
                >
                  Cancel
                </Button>
                {this.props.showActionButton && (
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={this.props.actionButtonDisabled}
                    id={this.props.actionButtonId}
                  >
                    {this.props.actionButtonLabel}
                  </Button>
                )}
              </BSModal.Footer>
            )}
          </form>
        )}
      </BSModal>
    );
  }

  static present<T = unknown>(props: ModalProps = {}): Promise<T> {
    return new Promise((resolve) => {
      const container = document.createElement('div');
      document.body.appendChild(container);
      ReactDOM.render(
        React.createElement(this, {
          onClose: (value: T) => {
            ReactDOM.unmountComponentAtNode(container);
            document.body.removeChild(container);
            resolve(value);
          },
          ...props,
        }),
        container,
      );
    });
  }
}

export const HelperModal: FC<Omit<ModalProps, 'actionButtonModal'>> = (props) => {
  const handleHelperIconClick = () => {
    Modal.present({ ...props, footer: false, noForm: true });
  };

  return (
    <>
      <IconQuestion
        className="hint"
        style={{ cursor: 'pointer' }}
        onClick={() => handleHelperIconClick()}
      />
    </>
  );
};

interface DialogModalProps extends BSModalProps {
  dialog: string | React.ReactNode;
  title: string;
  size: BSModalProps['size'];
  aktionButtonLabel?: React.ReactNode;
  cancelErrorMessage?: string;
}

export async function presentDialogModal({
  title,
  dialog,
  size = 'sm',
  aktionButtonLabel,
  cancelErrorMessage = 'CANCEL',
  ...props
}: DialogModalProps): Promise<void> {
  return new Promise((resolve, reject) => {
    const container = document.createElement('div');
    try {
      const handleOk = () => {
        document.body.removeChild(container);
        resolve();
      };
      document.body.appendChild(container);
      ReactDOM.render(
        <Modal
          title={title}
          onClose={() => reject(new Error(cancelErrorMessage))}
          actionButtonLabel={aktionButtonLabel || <div>OK</div>}
          onAction={handleOk}
          footer
          size={size}
          {...props}
        >
          {dialog}
        </Modal>,
        container,
      );
    } catch (err) {
      document.body.removeChild(container);
      reject(err);
    }
  });
}

type InfoModalProps = ModalProps & {
  buttonStyle?: CSSProperties;
  buttonTitle?: string;
  buttonEnabled?: boolean;
};

export const InfoModal: FC<InfoModalProps> = ({
  buttonEnabled,
  buttonStyle,
  buttonTitle,
  ...props
}) => {
  const handleHelperIconClick = () => {
    Modal.present({ ...props, footer: false, noForm: true });
  };

  return (
    <Button
      disabled={!buttonEnabled}
      style={buttonStyle}
      title={buttonTitle}
      onClick={() => handleHelperIconClick()}
    >
      <IconExclamationTriangle />
    </Button>
  );
};

type ButtonModalProps = ModalProps & {
  buttonTitle?: string;
  buttonVariant?: string;
  buttonStyle?: CSSProperties;
  buttonClass?: string;
  buttonId?: string;
  buttonIcon?: React.ReactElement;
  withFooter?: boolean;
};

export const ButtonModal: FC<ButtonModalProps> = ({
  buttonTitle,
  buttonVariant,
  buttonStyle,
  buttonClass,
  buttonId,
  buttonIcon,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const handleButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setShow(true);
  };

  return (
    <>
      <Button
        id={buttonId}
        variant={buttonVariant}
        style={buttonStyle}
        className={buttonClass}
        onClick={(event) => handleButtonClick(event)}
      >
        {buttonIcon != null && React.cloneElement(buttonIcon, { className: 'icon-btn-modal' })}{' '}
        {buttonTitle}
      </Button>
      <Modal
        show={show}
        standalone
        onShow={() => setShow(true)}
        onClose={() => setShow(false)}
        {...props}
      />
    </>
  );
};
