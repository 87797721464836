import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Center } from '../MetaComponent';

export const Preview = styled(Center)`
  background: white;
  padding: 10px;
  border: solid 1px ${RacemapColors.PaleBlue};
  border-radius: 5px;
  width: 100px;
`;
