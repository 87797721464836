import styled from '@emotion/styled';
import { Input } from 'antd';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { TreeNode } from '.';
import { CollapseState } from './CollapseButton';
import { TreeCtx, TreeDispatchCtx, defaultDragItemTarget } from './Context';
import { Leaf } from './Leaf';
import { DragItem } from './Tree';
import { getLastLevelPathes, getSelectionValue, getSubPathes } from './utils';

export type TreeItemElement = React.ReactElement<Props, typeof Node>;
export type TreeItemElements = Array<TreeItemElement> | TreeItemElement;
export interface TreeItemInfo {
  key: string;
  path: string;
  lastItemOfBranch: boolean;
  collapseState: CollapseState;
}

export interface Props {
  children?: TreeItemElements;
  itemKey: string;
  selectable?: boolean;
  label?: React.ReactNode;
  defaultCollapsed?: boolean;
  notDraggable?: boolean;
  count?: number;
  _level?: number;
  _withLines?: boolean;
  _lastItemOfBranch?: boolean;
  _parentTreeItemInfos?: Array<TreeItemInfo>;
  _collapsable?: boolean;
  searchValue?: string;
  searchable?: boolean;
  sortSelectedUp?: boolean;
}

export const Node: FC<Props> = ({
  children,
  itemKey,
  selectable = true,
  label,
  defaultCollapsed = false,
  notDraggable = false,
  count,
  _level = 1,
  _withLines = false,
  _lastItemOfBranch = false,
  _parentTreeItemInfos = [],
  _collapsable = false,
  searchable = false,
  sortSelectedUp = false,
}) => {
  const [collapseState, setCollapseState] = useState(
    defaultCollapsed ? CollapseState.Closed : CollapseState.Open,
  );
  const state = useContext(TreeCtx);
  const dispatch = useContext(TreeDispatchCtx);
  const [searchQuery, setSearchQuery] = useState('');
  const path = getPath(_parentTreeItemInfos, itemKey);
  const pathSelected = !!state?.selection?.has(path);
  const selectedSubPathes = getSubPathes(state?.selection, path);
  const subPathes = getSubPathes(state?.pathes, path);
  // if node has no subpathes, it is on the last level
  const lastLevelPathes = subPathes.length === 0 ? [path] : getLastLevelPathes(subPathes);
  const selectionValue = getSelectionValue(pathSelected, selectedSubPathes, lastLevelPathes);

  const nodeIsDraggable =
    !!state?.treeDraggable && !notDraggable && (state?.selection.size === 0 || pathSelected);
  const draggingItem: DragItem = {
    pathes:
      state?.selection != null && state.selection.size !== 0
        ? new Set(state.selection)
        : new Set(lastLevelPathes),
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: state?.dragItemTarget || defaultDragItemTarget,
      item: draggingItem,
      canDrag: () => nodeIsDraggable,
      isDragging: (monitor) => lastLevelPathes.every((p) => monitor.getItem().pathes.has(p)),
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [draggingItem, nodeIsDraggable],
  );

  const treeItemInfo: TreeItemInfo = {
    key: itemKey,
    path,
    lastItemOfBranch: _lastItemOfBranch,
    collapseState,
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const isTreeElement = (children: unknown): children is TreeItemElement => {
    return (
      children != null &&
      typeof children === 'object' &&
      'type' in children &&
      'props' in children &&
      children.props != null &&
      typeof children.props === 'object' &&
      'itemKey' in children.props &&
      children.props.itemKey != null
    );
  };

  const sortedChildren = sortSelectedUp
    ? React.Children.toArray(children)
        .filter(isTreeElement)
        .sort((c) => (state?.selection?.has(`${path}/${c.props.itemKey}`) ? -1 : 1))
    : children;
  const filteredChildren = searchable
    ? React.Children.toArray(sortedChildren)
        .filter(isTreeElement)
        .filter((c) => c.props.searchValue != null)
        .filter((c) => c.props.searchValue?.toLowerCase().includes(searchQuery))
    : sortedChildren;

  useEffect(() => {
    if (dispatch == null) return;
    dispatch({
      type: 'addPath',
      path: path,
    });

    return () => {
      if (dispatch == null) return;
      dispatch({
        type: 'removePath',
        path: path,
      });
    };
  }, []);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  });

  const handleSelectionChange = (newValue: boolean) => {
    if (state?.selection == null || dispatch == null) return;
    const newSelection = new Set(state.selection);
    const subPathes = [...lastLevelPathes].filter((p) => p.startsWith(path));

    if (newValue) {
      subPathes.forEach((p) => newSelection.add(p));
    } else {
      subPathes.forEach((p) => newSelection.delete(p));
    }

    dispatch({
      type: 'setSelection',
      selection: newSelection,
    });
  };

  return (
    <Container>
      <Leaf
        level={_level}
        label={label || itemKey}
        branchKey={itemKey}
        withLines={_withLines}
        lastItemOfBranch={_lastItemOfBranch}
        parentNodeInfos={_parentTreeItemInfos}
        selectable={selectable}
        selectionValue={selectionValue}
        isParent={subPathes.length === 0}
        onSelectionChange={handleSelectionChange}
        collapsed={_collapsable ? collapseState : undefined}
        onCollapseChange={setCollapseState}
        dragRef={drag}
        canDrag={nodeIsDraggable}
        isDragging={isDragging}
        count={count}
      />
      <FurtherBranch collapsed={collapseState === CollapseState.Closed}>
        {searchable && (
          <TreeNode
            itemKey="search"
            selectable={false}
            _level={_level + 1}
            _withLines={_withLines}
            _parentTreeItemInfos={[..._parentTreeItemInfos, treeItemInfo]}
            _lastItemOfBranch={!Array.isArray(filteredChildren) || filteredChildren.length === 0}
            label={
              <Input
                value={searchQuery}
                onChange={handleSearchInputChange}
                allowClear
                size="small"
                placeholder="Search ..."
              />
            }
          />
        )}
        {React.Children.map(filteredChildren, (c, i) => {
          if (c == null) return undefined;

          return React.cloneElement<Props>(c, {
            key: `${path}/${c.props.itemKey}`,
            selectable: c.props.selectable != null ? c.props.selectable : true,
            _level: _level + 1,
            _withLines,
            _lastItemOfBranch: !Array.isArray(children) || i === children.length - 1,
            _parentTreeItemInfos: [..._parentTreeItemInfos, treeItemInfo],
            _collapsable,
          });
        })}
      </FurtherBranch>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FurtherBranch = styled.div<{ collapsed: boolean }>`
  max-height: ${({ collapsed }) => (collapsed ? '0px' : '700px')};
  opacity: ${({ collapsed }) => (collapsed ? '0' : '1')};
  overflow: auto;

  transition: opacity 200ms linear, max-height 700ms ease;
`;

const getPath = (parentTreeInfos: Array<TreeItemInfo>, key: string): string => {
  return parentTreeInfos.length > 0
    ? `${parentTreeInfos[parentTreeInfos.length - 1].path}/${key}`
    : key;
};
