import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';
import { Tristate, tristateTask } from '../components/ButtonWithSpinner';
import { LoginForm } from '../components/LoginForm';
import { useStore } from '../store/reducers';

type LocationState = {
  nextPathname?: string;
};

export type Message = {
  error?: string;
  success?: string;
};

export default function LoginView(_props: RouteProps): React.JSX.Element {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const [isLoggingIn, setIsLoggingIn] = React.useState<Tristate>(Tristate.NORMAL);
  const [message, setMessage] = React.useState<Message | null>(null);
  const { login } = useStore((s) => s.users.actions);

  const handleLogin = async (email: string, password: string) => {
    setMessage(null);

    try {
      await tristateTask(
        () => login(email, password),
        (state) => setIsLoggingIn(state),
      );

      history.push(location.state.nextPathname || '/admin/');
    } catch (error) {
      setMessage({ error: 'Wrong email or password. Or no network connection.' });
      console.error(error);
    }
  };

  const handleForgetPasswordClick = () => {
    history.push('/auth/forgot-password', { fromLogin: true });
  };

  const handleRegisterClick = () => {
    history.push('/auth/register');
  };

  return (
    <Container>
      <Row>
        <Col sm={{ span: 6, offset: 3 }}>
          <h1 className="mt-5 mb-4">Login</h1>
          <LoginForm
            onLogin={handleLogin}
            isLoggingIn={isLoggingIn}
            message={message}
            onForgetPasswordClick={handleForgetPasswordClick}
            onRegisterClick={handleRegisterClick}
          />
        </Col>
      </Row>
    </Container>
  );
}
