import classnames from 'classnames';
import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  name: string;
  className?: string;
};

function Icon({ name, className, ...props }: Props) {
  return (
    <img
      className={classnames('icon', 'icon-svg', className)}
      src={`/img/general-icons/${name}.svg`}
      {...props}
      alt={name}
    />
  );
}

export const MapIcon = ({ name, className, ...props }: Props) => {
  return (
    <img
      className={classnames('icon', 'icon-svg', className)}
      src={`/img/map-icons/${name}_tickless.svg`}
      {...props}
      alt="Map Icon"
    />
  );
};

type WrapperProps = Omit<Props, 'name'>;

export const IconMap = (props: WrapperProps) => <Icon {...props} name="map" />;
export const IconLeaderboard = (props: WrapperProps) => <Icon {...props} name="leaderboard" />;
export const IconDatafeed = (props: WrapperProps) => <Icon {...props} name="datafeed" />;
export const IconStage = (props: WrapperProps) => <Icon {...props} name="stage" />;
export const IconGroup = (props: WrapperProps) => <Icon {...props} name="group" />;
export const IconSponsor = (props: WrapperProps) => <Icon {...props} name="sponsor" />;
export const IconDevices = (props: WrapperProps) => <Icon {...props} name="devices" />;
export const IconPageviews = (props: WrapperProps) => <Icon {...props} name="pageviews" />;
export const IconOffline = (props: WrapperProps) => <Icon {...props} name="rm-signal-empty-bar" />;
export const IconRemoveMessage = (props: WrapperProps) => <Icon {...props} name="removeMessages" />;
export const IconRemoveSOS = (props: WrapperProps) => <Icon {...props} name="removeSOS" />;
