import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { ID } from '@racemap/utilities/types/utils';
import { Immutable } from 'immer';
import { FC } from 'react';
import { CurrentEvent } from '../../../store/events/events_reducers';

type Props = {
  eventId?: ID;
  event?: Immutable<CurrentEvent | RacemapEvent>;
  src?: string;
  size?: number;
  rounded?: boolean;
};

export const EventLogo: FC<Props> = ({ eventId, event, src, size = 80, rounded = false }) => {
  let finalSrc = '/img/default_logo.png';
  if (src != null) {
    finalSrc = src;
  } else if (event != null && event.images?.app != null) {
    finalSrc = event.images.app;
  } else if (eventId != null) {
    finalSrc = `/api/events/${eventId}/logo`;
  }

  return (
    <div>
      <Logo src={finalSrc} alt="eventLogo" size={size} rounded={rounded} />
    </div>
  );
};

const Logo = styled.img<{ size: number; rounded: boolean }>`
  border: solid 1px ${RacemapColors.DarkGray};
  border-radius: ${({ rounded }) => (rounded ? '15px' : 'unset')};
  width: ${({ size }) => `${size}px`};
  background: white;
`;
