import { ObjectId } from '@racemap/sdk/schema/base';
import { HTTPFetchError } from '@racemap/utilities/api-client';
import { RacemapEvent } from '@racemap/utilities/types/events';
import { Immutable } from 'immer';
import React, { useState } from 'react';
import { Alert, AlertProps, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/reducers';
import SearchEvent from '../../SearchEvent';

enum PROCESS_STATE {
  INITED = 0,
  FETCHING = 1,
  FINISHED = 2,
  FAILURE = 3,
}

type Props = {
  trackerIds: Array<ObjectId>;
  event?: Immutable<RacemapEvent> | null;
  onAddToEvent?: () => void;
};

export function AddTrackerToEvent({ trackerIds, event = null, onAddToEvent = () => {} }: Props) {
  const [createNewStarter, setCreateNewStarter] = useState(true);
  const [processState, setProcessState] = useState<PROCESS_STATE>(PROCESS_STATE.INITED);
  const [message, setMessage] = useState<React.ReactNode | null>(null);
  const [messageType, setMessageType] = useState<AlertProps['variant']>('success');
  const [eventId, setEventId] = useState<string | null>(event?.id || null);
  const { addTrackersToEvent } = useStore((s) => s.trackers.actions);

  if (trackerIds == null) return <></>;

  const handleAddTrackerToEvent = async () => {
    if (eventId != null && trackerIds.length > 0) {
      try {
        setProcessState(PROCESS_STATE.FETCHING);
        const { success, count } = await addTrackersToEvent(trackerIds, eventId, createNewStarter);

        if (!success) throw new Error('Failed to add trackers!');
        if (count === 0) {
          setProcessState(PROCESS_STATE.FINISHED);
          setMessageType('warning');
          setMessage(
            <div>
              {
                'We added no trackers to the event. Maybe all starters already connected and you disabled to create new starter or all device tracker are already connected in that event.'
              }
              <div className="mb-1" />
              <Link to={`/admin/events/${eventId}/participants`}>Switch here to the event</Link>
            </div>,
          );
        } else {
          setProcessState(PROCESS_STATE.FINISHED);
          setMessage(
            <div>
              {`Success, added ${count} trackers to the event!`}
              <br />
              <Link to={`/admin/events/${eventId}/participants`}>Switch here to the event</Link>
            </div>,
          );
        }
      } catch (err) {
        setProcessState(PROCESS_STATE.FAILURE);
        if (err instanceof HTTPFetchError) {
          setMessage(`Failed to connect the trackers with the starter. ${err.serverError}`);
        } else {
          setMessage('Failed to connect the trackers with the starter.');
        }
        setMessageType('danger');
      }
    }
  };

  const handleClose = () => {
    setMessage(null);
    setMessageType('success');
    onAddToEvent();
    setProcessState(PROCESS_STATE.INITED);
  };

  return (
    <div>
      Try to add {trackerIds.length} selected tracker to an event.
      {event != null ? (
        <h4 className="d-flex justify-content-center align-items-center p-3">{event.name}</h4>
      ) : (
        <div className="d-flex justify-content-center align-items-center p-3">
          <SearchEvent
            filter="can-edit"
            limit={5}
            onSelect={(event) => setEventId(event?.id || null)}
            inputStyle={{ width: 300 }}
          />
        </div>
      )}
      <div className="pb-2">
        <Form.Check
          type="checkbox"
          label="Create new participant, if not enough exists"
          checked={createNewStarter}
          onChange={({ target }) => {
            setCreateNewStarter(target.checked);
          }}
        />
      </div>
      {message != null && <Alert variant={messageType}>{message}</Alert>}
      <div className="p-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleClose} style={{ marginRight: '7px' }}>
          Close
        </Button>

        {processState !== PROCESS_STATE.FINISHED && (
          <Button
            onClick={handleAddTrackerToEvent}
            variant="primary"
            disabled={eventId == null || processState === PROCESS_STATE.FETCHING}
          >
            Add
          </Button>
        )}
      </div>
    </div>
  );
}
