import { UnitType } from '@racemap/utilities/consts/events';
import { OneMinuteInMillis, OneSecondInMillis } from '@racemap/utilities/consts/time';
import { formatTimeDuration, timeDurationToMilliseconds } from '@racemap/utilities/formatting';
import { PathInto, TypeOfPath } from '@racemap/utilities/types/utils';
import { produce } from 'immer';
import set from 'lodash/set';
import moment from 'moment';
import { NoValue } from '../misc/consts';

export const shortStr = (
  aString: string | null | undefined,
  letters = 4,
  reverse = false,
): string => {
  if (aString != null) {
    if (aString.length > letters) {
      if (reverse) {
        return `...${aString.substring(aString.length - letters)}`;
      } else {
        return `${aString.substring(0, letters)}...`;
      }
    } else {
      return aString;
    }
  }
  return NoValue;
};

export const durationToStr = (
  durationInMillis: number | null = null,
  format = 'HH:mm:ss.SS',
): string => {
  if (durationInMillis != null && moment(durationInMillis).isValid()) {
    return moment.utc(durationInMillis).format(format);
  }
  return NoValue;
};

type ValueProps<T> = {
  value: T;
  onChange: (arg0: T) => void;
};

export function withValueConverter<T, U>(
  { onChange, value }: ValueProps<T>,
  toValue: (arg0: T) => U,
  fromValue: (arg0: U) => T,
): ValueProps<U> {
  return {
    onChange: (value: U) => onChange(fromValue(value)),
    value: toValue(value),
  };
}

export function inKilometers(props: ValueProps<number>): ValueProps<number> {
  return withValueConverter(
    props,
    (value: number) => value / 1000,
    (value: number) => value * 1000,
  );
}

export function inMiles(props: ValueProps<number>): ValueProps<number> {
  // converts meters to miles
  return withValueConverter(
    props,
    (value: number) => value * 0.00062137,
    (value: number) => value / 0.00062137,
  );
}

export function asNumbers(props: ValueProps<number>): ValueProps<string> {
  return withValueConverter<number, string>(
    props,
    (value) => value.toString(),
    (value) => Number(value),
  );
}

export function inSeconds(props: ValueProps<number>): ValueProps<number> {
  return withValueConverter(
    props,
    (value) => (value === -1 ? -1 : value / OneSecondInMillis),
    (value) => Math.max(-1, value * OneSecondInMillis),
  );
}

export function inMinutes(props: ValueProps<number>): ValueProps<number> {
  return withValueConverter(
    props,
    (value) => (value === -1 ? -1 : value / OneMinuteInMillis),
    (value) => Math.max(-1, value * OneMinuteInMillis),
  );
}

export function inTimeDuration(props: ValueProps<number>): ValueProps<string> {
  return withValueConverter(
    props,
    (value) => (value === -1 ? '-1' : formatTimeDuration(value)),
    (value) => (value === '-1' ? -1 : timeDurationToMilliseconds(value)),
  );
}

export function inDistanceBasedOnUnitType(
  props: ValueProps<number>,
  unitType: string,
): ValueProps<number> {
  if (unitType === UnitType.IMPERIAL) return inMiles(props);

  return inKilometers(props);
}

export function keySplitter(key: string): Array<string> {
  return key.split('.');
}

export function getter<T>(keyPath: Array<string>, root: any): T {
  let result = root;
  keyPath.forEach((keyPart) => {
    result = result?.[keyPart] !== undefined ? result[keyPart] : undefined;
  });
  return result;
}

export function updateValueNested<T extends Record<string, unknown>, P extends PathInto<T>>(
  root: T | undefined | null,
  key: P,
  value: TypeOfPath<T, P>,
): T | undefined {
  if (root == null) return undefined;

  return produce(root, (obj: object) => {
    set(obj, key, value);
  });
}

export function makeGetter<T extends Record<string, any>>(value: T) {
  return function <P extends PathInto<T>>(key: P): TypeOfPath<T, P> {
    return getter(keySplitter(key), value);
  };
}
