import TestSelectors from '@racemap/utilities/consts/test_selectors';
import { FC } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCurrentUser, useIsAdmin, useIsMobile } from '../../lib/customHooks';
import { LinkEntry } from './LinkEntry';
import { SectionHeader } from './SectionHeader';
import { useIsNavLinkActive } from './utils';

interface Props {
  toggleFakeUserState: () => void;
}

export const AdminLinks: FC<Props> = ({ toggleFakeUserState }) => {
  const isAdmin = useIsAdmin();
  const currentUser = useCurrentUser();
  const isMobile = useIsMobile();
  const { isActive } = useIsNavLinkActive();

  if (currentUser == null) {
    return null;
  }

  const content = (
    <>
      <LinkEntry
        key="users"
        as={Link}
        to="/admin/users"
        id={TestSelectors.admin.nav.sections.users.toString()}
        active={isActive('/admin/users')}
      >
        Users
      </LinkEntry>
      <LinkEntry
        key="scripts"
        as={Link}
        to="/admin/scripts"
        id={TestSelectors.admin.nav.sections.scripts.toString()}
        active={isActive('/admin/scripts')}
      >
        Scripts
      </LinkEntry>
      <LinkEntry
        key="manage"
        as={Link}
        to="/admin/events/manage"
        id={TestSelectors.admin.nav.sections.events.toString()}
        active={isActive('/admin/events/manage')}
      >
        Events
      </LinkEntry>
      <LinkEntry
        key="billing-report"
        as={Link}
        to="/admin/billing/report"
        id={TestSelectors.admin.nav.sections.billing.toString()}
        active={isActive('/admin/billing/report')}
      >
        Billing Report
      </LinkEntry>
      <LinkEntry
        key="predictive-emulation"
        as={Link}
        to="/admin/predictive/emulation"
        id={TestSelectors.admin.nav.sections.predictiveEmulation.toString()}
        active={isActive('/admin/predictive/emulation')}
      >
        Predictive Emulation
      </LinkEntry>
      <LinkEntry
        key="message-templates"
        as={Link}
        to="/admin/message-templates"
        id={TestSelectors.admin.nav.sections.messageTemplates.toString()}
        active={isActive('/admin/message-templates')}
      >
        Message Templates
      </LinkEntry>
      {!isMobile && <NavDropdown.Divider />}
      <LinkEntry>
        <div
          onClick={toggleFakeUserState}
          onKeyUp={(e) => e.key === 'Enter' && toggleFakeUserState()}
        >
          {currentUser.fakeUser ? 'Switch back to admin mode' : 'Switch to fake user mode'}
        </div>
      </LinkEntry>
    </>
  );

  if (!isAdmin) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <SectionHeader>Admin</SectionHeader>
        <div>{content}</div>
      </>
    );
  }

  return (
    <div className="admin-links-not-collapsed">
      <NavDropdown
        id="admin"
        title="Admin"
        className={TestSelectors.admin.nav.dropdowns.admin.toString()}
        active={
          isActive('/admin/users') ||
          isActive('/admin/scripts') ||
          isActive('/admin/events/manage') ||
          isActive('/admin/billing/report') ||
          isActive('/admin/predictive/emulation')
        }
      >
        {content}
      </NavDropdown>
    </div>
  );
};
