import { bsonSerialize, ejsonStringify } from '@racemap/sdk/schema/base';

export function downloadBlob(blob: Blob, filename: string): void {
  if (document == null || window == null)
    throw new Error('Function works only in browser context.');

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(link.href);
}

export function downlaodAsJson(exportObj: unknown, exportName: string) {
  const blob = new Blob([JSON.stringify(exportObj, undefined, 2)], { type: 'application/json' });
  downloadBlob(blob, `${exportName}.json`);
}

export function downloadAsBson(exportObj: Document, exportName: string) {
  const blob = new Blob([bsonSerialize(exportObj)], { type: 'application/bson' });
  downloadBlob(blob, `${exportName}.bson`);
}

export function downloadAsEJson(exportObj: unknown, exportName: string) {
  const blob = new Blob([ejsonStringify(exportObj, undefined, 2)], { type: 'application/json' });
  downloadBlob(blob, `${exportName}.json`);
}
