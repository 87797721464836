import { BillableItemTypes } from '@racemap/sdk/schema/user';

export enum EventTypes {
  REGULAR = 'REGULAR',
  CONTEST = 'CONTEST',
  CONTEST_GROUP = 'CONTEST_GROUP',
  STAGE = 'STAGE',
  STAGE_GROUP = 'STAGE_GROUP',
}

export const AtomicEvents: Array<string> = [
  EventTypes.REGULAR,
  EventTypes.STAGE,
  EventTypes.CONTEST,
];

export enum VisibilityStates {
  DRAFT = 'DRAFT',
  UNLISTED = 'UNLISTED',
  LISTED = 'LISTED',
  PUBLIC = 'PUBLIC',
  ARCHIVED = 'ARCHIVED',
}

export const VisibilityLabels: Record<string, string> = {
  [VisibilityStates.DRAFT]: 'Draft',
  [VisibilityStates.UNLISTED]: 'Unlisted',
  [VisibilityStates.LISTED]: 'Listed',
  [VisibilityStates.PUBLIC]: 'Open',
  [VisibilityStates.ARCHIVED]: 'Archived',
};

export enum AuthorizationStates {
  NONE = 'NONE',
  PAID = 'PAID',
  PAID_FREE_LOADINGS = 'PAID_FREE_LOADINGS',
  FREE = 'FREE',
}

export enum TimingSystems {
  NONE = 'NONE',
  RACERESULT = 'RACERESULT',
  CHRONO_TRACK = 'CHRONO_TRACK',
  FEIBOT = 'FEIBOT',
  OTHER = 'OTHER',
}

export const TimingSystemLabels: Record<TimingSystems, string> = {
  [TimingSystems.NONE]: 'None',
  [TimingSystems.RACERESULT]: 'RACE RESULT',
  [TimingSystems.CHRONO_TRACK]: 'ChronoTrack',
  [TimingSystems.FEIBOT]: 'Feibot',
  [TimingSystems.OTHER]: 'Other',
};

export enum TrackAvailibilityStates {
  WITH_TRACK = 'WITH_TRACK',
  WITHOUT_TRACK = 'WITHOUT_TRACK',
}

export const TrackAvailibilityLabels = {
  [TrackAvailibilityStates.WITH_TRACK]: 'With Predefined Track',
  [TrackAvailibilityStates.WITHOUT_TRACK]: 'Without Predefined Track',
};

export enum DeviceClasses {
  Tracker = 'Tracker',
  Mobile = 'Mobile',
  Manual = 'Manual',
  Integration = 'Integration',
  TrackPingTransponder = 'TrackPingTransponder',
  ChronoTrackTransponder = 'ChronoTrackTransponder',
  TrackBox = 'TrackBox',
  Upload = 'Upload',
  Transponder = 'Transponder',
  FeibotTransponder = 'FeibotTransponder',
}

export const TransponderClasses = [
  DeviceClasses.TrackPingTransponder,
  DeviceClasses.ChronoTrackTransponder,
  DeviceClasses.FeibotTransponder,
  DeviceClasses.Transponder,
];

export const DeviceClassLabels = {
  [DeviceClasses.Tracker]: 'Tracker',
  [DeviceClasses.Mobile]: 'Mobile',
  [DeviceClasses.Manual]: 'Manual',
  [DeviceClasses.Integration]: 'Integration',
  [DeviceClasses.TrackPingTransponder]: 'TrackPingTransponder',
  [DeviceClasses.ChronoTrackTransponder]: 'ChronoTrackTransponder',
  [DeviceClasses.TrackBox]: 'TrackBox',
  [DeviceClasses.Upload]: 'Upload',
  [DeviceClasses.Transponder]: 'Transponder',
  [DeviceClasses.FeibotTransponder]: 'FeibotTransponder',
};

export enum UnitType {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

export enum MapType {
  SATELLITE = 'SATELLITE',
  TERRAIN = 'TERRAIN',
  HYBRID = 'HYBRID',
  ROADMAP = 'ROADMAP',
  DARK = 'DARK',
}

export enum DeviceStateFlag {
  DISQUALIFIED = 'DSQ',
  DIDNOTSTART = 'DNS',
  DIDNOTFINISH = 'DNF',
  NORANK = '-',
}

export enum Targets {
  DISTANCE = 'DISTANCE',
  DURATION = 'DURATION',
}

export enum PinToShadowTrackMode {
  NONE = 'NONE',
  MAGNETIC = 'MAGNETIC',
  FORCED = 'FORCED',
}

export enum FlagContent {
  STARTNUMBER = 'STARTNUMBER',
  NAME = 'NAME',
  STARTNUMBERANDNAME = 'STARTNUMBER_AND_NAME',
}

export enum TimeMode {
  RELATIVE = 'RELATIVE',
  ABSOLUTE = 'ABSOLUTE',
}

export enum PlayerInfoSheetTabKeys {
  INFO = 'INFO',
  ELEVATION = 'ELEVATION',
  FLOW = 'FLOW',
  STATS = 'STATS',
  TIMING = 'TIMING',
}

export const PlayerInfoSheetTabLabels = {
  [PlayerInfoSheetTabKeys.INFO]: 'Event Infos',
  [PlayerInfoSheetTabKeys.ELEVATION]: 'Elevation Chart',
  [PlayerInfoSheetTabKeys.FLOW]: 'Event Flow',
  [PlayerInfoSheetTabKeys.STATS]: 'Statistics',
  [PlayerInfoSheetTabKeys.TIMING]: 'Leaderboard',
};

export enum LeaderboardHeaderContent {
  NAME = 'NAME',
  ICON = 'ICON',
  DISTANCE = 'DISTANCE',
}

export const LeaderboardHeaderContentLabels = {
  [LeaderboardHeaderContent.NAME]: 'Name',
  [LeaderboardHeaderContent.ICON]: 'Icon',
  [LeaderboardHeaderContent.DISTANCE]: 'Distance',
};

export enum AppScreenKeys {
  PLAYER = 'PLAYER',
  LEADERBOARD = 'LEADERBOARD',
}

export const AppScreenLabels = {
  [AppScreenKeys.PLAYER]: 'Map',
  [AppScreenKeys.LEADERBOARD]: 'Leaderboard',
};

export enum AddOns {
  TIMING = 'timing',
  MONITOR = 'monitor',
  SPONSOR = 'sponsor',
  API = 'dataFeed',
  PREDICTIVE = 'predictive',
  KEYS = 'passcode_keys',
  PARTICIPANTS = 'participants',
}

export const AddOnLabels: Record<AddOns, string> = {
  [AddOns.TIMING]: 'Leaderboard',
  [AddOns.MONITOR]: 'Monitor',
  [AddOns.SPONSOR]: 'Sponsor Logo',
  [AddOns.API]: 'Data API',
  [AddOns.PREDICTIVE]: 'Predictive',
  [AddOns.KEYS]: 'Passcode & Keys',
  [AddOns.PARTICIPANTS]: 'Participants',
};

export const numberOfActivationPeriod = 3;
export const unitOfActivationPeriod = 'month';

export enum ModulesStates {
  NONE = 'NONE',
  /** @deprecated modules should only be activated or not */
  ENABLED = 'ENABLED',
  ACTIVATED = 'ACTIVATED',
}

export enum LeaderboardType {
  DEFAULT = 'DEFAULT',
  EXTERNAL = 'EXTERNAL',
  RACE_RESULT = 'RACE_RESULT',
}

export enum PanelState {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  PRIMARY = 'primary',
  NONE = 'secondary',
}
