import React, { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormGroupHint } from '../FormComponents';
import { IconDevices } from '../IconImg';

type Props = {
  label: React.ReactNode;
  value: number;
  onChange: (count: number) => void;
  max: number;
  min: number;
  step: number;
};

export const RangeCountForm: FC<Props> = ({ label, value, onChange, max, min, step }) => {
  return (
    <Form>
      <Form.Group controlId="formBasicRangeCustom">
        <Form.Label>{label}</Form.Label>
        <Row style={{ alignItems: 'center' }}>
          <Col sm={10}>
            <Form.Control
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={({ target }) => {
                onChange(parseInt(target.value, 10));
              }}
              type="range"
              custom
            />
          </Col>
          <Col sm={2}>
            <Form.Control
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={({ target }) => {
                onChange(parseInt(target.value, 10));
              }}
              type="number"
            />
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};
